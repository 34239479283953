import {createAction, handleActions} from 'redux-actions';
import * as api from '../apis/User';
import createRequestThunk from "../lib/createRequestThunk";

// ActionTypes
const GET_USER_LIST = 'user/GET_USER_LIST';
const GET_USER_LIST_SUCCESS = 'user/GET_USER_LIST_SUCCESS';
const APPLY_USER = 'user/APPLY_USER';

// thunk
export const getUserList = createRequestThunk(GET_USER_LIST, api.getUserList)
export const applyUser = createRequestThunk(APPLY_USER, api.applyUser)

// 초기화 상태 선언
const initialState = {
	data: null
}

const User = handleActions(
	{
		[GET_USER_LIST_SUCCESS]: (state, action) => ({
			...state,
			data: action.payload
		}),
		[APPLY_USER]: (state, action) => ({
			...state,
			data: action.payload
		}),
	},
	initialState
)

export default User;
