import { createAction, handleActions } from 'redux-actions';
import * as api from '../apis/SignList';
import createRequestThunk from "../lib/createRequestThunk";

// ActionTypes
const GET_LIST = 'signList/GET_LIST';
const GET_LIST_SUCCESS = 'signList/GET_LIST_SUCCESS';

const GET_DETAIL_VIEW = 'signList/GET_DETAIL_VIEW';
const GET_DETAIL_VIEW_SUCCESS = 'signList/GET_DETAIL_VIEW_SUCCESS';

const GET_DETAIL_VIEWER = 'signList/GET_DETAIL_VIEWER';
const GET_DETAIL_VIEWER_SUCCESS = 'signList/GET_DETAIL_VIEWER_SUCCESS';

const SAVE_SIGN_IMG = 'signList/SAVE_SIGN_IMG';

// thunk
export const getList = createRequestThunk(GET_LIST, api.getList);
export const getDetailView = createRequestThunk(GET_DETAIL_VIEW, api.getDetailView);
export const getDetailViewer = createRequestThunk(GET_DETAIL_VIEWER, api.getDetailViewer);
export const saveSignImg = createAction(SAVE_SIGN_IMG);

export const addSaveSignImg = (img) => dispatch => {
	dispatch(saveSignImg(img));
}

// 초기상태 선언
const initialState = {
	list: null,
	detailView: null,
	detailViewer: null,
	signImgList: []
};

const SignList = handleActions(
	{
		[GET_LIST_SUCCESS]: (state, action) => ({
			...state,
			list: action.payload,
			detailView: null
		}),
		[GET_DETAIL_VIEW_SUCCESS]: (state, action) => ({
			...state,
			detailView: action.payload
		}),
		[GET_DETAIL_VIEWER_SUCCESS]: (state, action) => ({
			...state,
			detailViewer: action.payload
		}),
		[SAVE_SIGN_IMG]: (state, action) => ({
			...state,
			signImgList: action.payload
		}),
	},
	initialState
);

export default SignList;
