const session = {};

session.set = (key, object) => {
	sessionStorage[key] = JSON.stringify(object);
};

session.get = (key) => {
	if (!sessionStorage[key]) {
		return undefined;
	}
	return JSON.parse(sessionStorage[key]);
};

session.remove = (key) => {
	if (sessionStorage[key]) {
		sessionStorage.removeItem(key);
	}
};

export default session;
