import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {addSaveSignImg} from '../../../modules/SignList';
import {makeStyles} from "@material-ui/core/styles";
import SignaturePad from "signature_pad";
import {isEmpty} from "../../../lib/utils";
import {Button} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";

const useStyles = makeStyles((theme) => ({
	section: {
		marginTop: "30px",
		padding: "20px 0",
		lineHeight: 1.6,
		borderTop: "1px solid #333",
		"& p": {
			marginTop: "10px"
		}
	},
	bold: {
		fontWeight: 700
	},
	alignRight: {
		textAlign: "right"
	},
	box: {
		margin: "15px 0",
		padding: "15px",
		border: "1px solid #333"
	},
	signPad: {
		marginTop: "20px",
		textAlign: "right",
		"& .signature": {
			display: "inline-block",
			width: "335px",
			height: "200px",
			border: "1px dotted #333"
		}
	}
}));

let canvas;
let signaturePad;

const UserSurvey01 = ({mode, idx, userName, pxdName, email, date, addSaveSignImg, signImg, signImgList}) => {
	const classes = useStyles();
	// const [signFlag, setSignFlag] = useState(false);

	useEffect(() => {
		if (mode === 'viewer') {
			canvas = document.querySelector(`#signature${idx}`);
			signaturePad = new SignaturePad(canvas);
		}
	}, []);

	const saveSign = (e) => {
		let data = signaturePad.toDataURL('image/png');

		if (signaturePad.isEmpty()) {
			alert("서명 후 저장해주세요.");
			return false;
		}

		// setSignFlag(true);
		signImgList(0, data);
		alert('저장되었습니다.');

		let thisEl = e.target.closest('.section');
		let nextEl = thisEl.nextElementSibling;
		let nextElFlag = nextEl && thisEl.nextElementSibling.classList.contains('section');

		if (nextElFlag) {
			// const y = nextEl.getBoundingClientRect().top + window.scrollY;

			let ua = window.navigator.userAgent;
			let msie = ua.indexOf("MSIE ");

			if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
				// If Internet Explorer
				window.scrollTo(0, nextEl.offsetTop)
			} else  {
				// If another browser
				// not support ios safari -_-;;
				window.scroll({
					top: nextEl.offsetTop,
					behavior: 'smooth'
				});
			}
		}
	};

	const clearSign = () => {
		signaturePad.clear();
	};

	const setSignPad = () => {
		return(
			<div className={classes.signPad}>
				<canvas id={`signature${idx}`} className="signature" width="335px" height="200px"></canvas>
				<div className={classes.wrapBtn}>
					<Button
						className="clear"
						variant="contained"
						// disabled={signFlag}
						startIcon={<ClearIcon/>}
						onClick={clearSign}
					>
						Clear
					</Button>&nbsp;&nbsp;&nbsp;&nbsp;
					<Button
						className="save"
						variant="contained"
						color="primary"
						// disabled={signFlag}
						startIcon={<CreateIcon/>}
						onClick={(e) => saveSign(e)}
					>
						Save
					</Button>
				</div>
			</div>
		)
	}

	const signView = (imgSrc) => {
		if (!isEmpty(imgSrc)) {
			return(
				<div className={classes.signPad}>
					<img src={imgSrc} className="signature" alt="사인 이미지"/>
				</div>
			)
		}
	}

	return(
		<div className={`${classes.section} section`}>
			<p>안녕하세요? <span className={classes.bold}>{userName}</span>님</p>
			<p>먼저 본 조사에 참여해 주셔서 진심으로 감사드립니다.</p>
			<p>본 조사를 진행하는 ㈜피엑스디는 2002년 설립 이래 사용자조사 및 사용성 테스트 등을 통한 사용자 경험을 개선해 온 UX Consulting 전문회사입니다.</p>
			<p>이 조사는 ㈜피엑스디와 조사 결과 정보를 제공받는 제3자의 연구를 위해 진행합니다. 이번 조사를 통해 관찰&#183;작성된 내용은 연구자들이 자료를 분석하는데 사용될 예정입니다. 또한 조사를 통해 얻어진 정보는 연구 목적 이외 일체의 다른 용도로 사용되지 않으며 외부로 절대 유출되지 않습니다.</p>
			<p>다음 문장을 읽어 보신 후 서명해 주시면 감사드리겠습니다.</p>

			<div className={classes.box}>
				<p>조사에 참여하는 본인은 이번 조사에서 특정 서비스 혹은 제품에 관련하여 이용 과정, 사용패턴, 대상에 대한 본인의 생각, 느낌 및 의견 등을 파악하는 것임을 이해하고 있습니다.</p>
				<p>본인은 이번 조사에서 실제 제품 사용, 사진 촬영, 음성 녹음 등이 이루어 질 수 있음을 알고 있습니다.</p>
				<p>본인은 조사 과정에서 본인이 불쾌하거나 답변을 원하지 않는 질문/과업에 대해 대답하지 않을 수 있음을 알고 있고, 이에 대한 어떠한 불이익도 없음을 알고 있습니다.</p>
				<p>본인은 조사 과정에서 윤리적 혹은 법적으로 나의 기준에 부합하지 않는다고 생각하는 경우 언제라도 그만 둘 수 있는 권리가 있음을 알고 있으며, 이에 따른 어떠한 불이익도 없다는 것을 알고 있습니다.</p>
				<p>본인은 조사 기간 동안 아직 대중에게 공개되지 않은 사항에 대한 정보를 습득할 수 있음을 인지하고 있습니다. 해당 경우 본인은 조사기간 동안 습득한 내용을 본 조사에 참여하지 않은 제3자에게 전달하지 않을 것입니다.</p>
				<p>본 조사의 책임자는 아래와 같습니다.</p>
				<p>책임자: <span className={classes.bold}>{pxdName}</span></p>
				<p>이메일: <span className={classes.bold}>{email}</span></p>
			</div>
			<p className={classes.alignRight}>{date}</p>
			<p className={classes.alignRight}>본인 : <span className={classes.bold}>{userName}</span>(자필 필요) 인</p>

			{ mode === 'view' && signView(signImg) }
			{ mode === 'print' && signView(signImg) }
			{ mode === 'viewer' && setSignPad() }
		</div>
	)
};

export default connect(
	({SignList}) => ({

	}),
	{
		addSaveSignImg,
	}
)(UserSurvey01);
