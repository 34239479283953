import {createAction, handleActions} from 'redux-actions';
import * as api from '../apis/Login';
import createRequestThunk from "../lib/createRequestThunk";

// ActionTypes
const LOGIN = 'login/LOGIN';
const LOGIN_SUCCESS = 'login/LOGIN_SUCCESS';

const LOGOUT = 'login/LOGOUT';
export const logout = createAction(LOGOUT);

// thunk
export const login = createRequestThunk(LOGIN, api.login)
export const logoutBye = () => dispatch => {
	dispatch(logout());
}

// 초기화 상태 선언
const initialState = {
	data: {}
}

const Login = handleActions(
	{
		[LOGIN_SUCCESS]: (state, action) => ({
			...state,
			data: action.payload
		}),
		[LOGOUT]: (state, action) => ({
			...state,
			data: {}
		}),
	},
	initialState
)

export default Login;
