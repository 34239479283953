import React from 'react';
import COVID19QNA from "../../../components/templates/COVID19/COVID19QNA";

const COVID19ViewContainer = ({mode, detailView, history}) => {
	const viewData = () => {
		console.log('viewData')
	}
	return(
		<>
			<div>
				<p>코로나바이러스감염증-19(COVID-19) 관련 사실 확인 여부 상세</p>
				<COVID19QNA mode={mode} detailView={detailView} propsData={viewData} />
			</div>
		</>
	)
};

export default COVID19ViewContainer;
