import React, { useState, useEffect } from "react";
import UserSurvey01 from "../../../components/templates/UserSurvey/UserSurvey01";
import UserSurvey02 from "../../../components/templates/UserSurvey/UserSurvey02";
import UserSurvey03 from "../../../components/templates/UserSurvey/UserSurvey03";
import UserSurvey04 from "../../../components/templates/UserSurvey/UserSurvey04";

import moment from "moment";
import "moment/locale/ko";

import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import { Button } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import { isEmpty } from "../../../lib/utils";
import session from "../../../lib/sessionStorage";

moment.locale("ko");

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "10px",
  },
  wrapBtn: {
    padding: "10px",
    textAlign: "right",
  },
  download: {
    display: "block",
    color: "#333",
    textAlign: "right",
  },
}));

const UserSurveyWriteContainer = ({ location, data, applyChecker, lang }) => {
  const classes = useStyles();
  const [section, setSection] = useState({
    UserSurvey01: 1,
    UserSurvey02: 1,
    UserSurvey03: 1,
    UserSurvey04: 1,
  });
  /** UserSurvey01 */
  const [userName, setUserName] = useState("홍길동");
  const [pxdName, setPxdName] = useState("피엑스디 책임연구원(PM이름)");
  const [email, setEmail] = useState("pxd@pxd.co.kr");
  /** UserSurvey02 */
  const [projectName, setProjectName] = useState("프로젝트");
  const [clientName, setClientName] = useState("클라이언트명");
  // userName
  /** UserSurvey03 */
  /** UserSurvey04 */
  // userName, projectName
  const date = moment().format("LL");
  const [apply, setApply] = useState(false);

  useEffect(() => {
    setApply(false);
    applyChecker(false);
  }, [userName, pxdName, email, date, projectName, clientName]);

  useEffect(() => {
    let cnt = 0;
    Object.keys(section).forEach((v, i) => {
      if (section[v] === 1) {
        cnt++;
      }
    });
    if (cnt === 0) {
      alert("1개 이상의 선택이 필요해요.");
      setSection({
        UserSurvey01: 1,
        UserSurvey02: 1,
        UserSurvey03: 1,
        UserSurvey04: 1,
      });
    }
  }, [section]);

  const handleChange = (e) => {
    setSection({
      ...section,
      [e.target.name]: e.target.checked ? 1 : 0,
    });
  };

  // 이메일 체크
  const isEmail = (value) => {
    let regExp =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    return regExp.test(value); // 형식에 맞는 경우 true 리턴
  };

  // 핸드폰 번호 체크
  const isCelluar = (value) => {
    let regExp = /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/;
    return regExp.test(value); // 형식에 맞는 경우 true 리턴
  };

  const onApply = () => {
    let el = document.querySelectorAll("[name=text-field]");
    let el_arr = [...el];
    let checker = false;

    el_arr.some((v, i) => {
      if (isEmpty(v.value)) {
        checker = false;
        v.focus();
        return true;
      } else {
        checker = true;
      }
    });

    if (!isEmail(email)) {
      alert("이메일 형식에 맞게 입력하세요.");
      let el = document.querySelector(".email input");
      el.focus();
      return false;
    }

    if (checker) {
      let sendData = {
        sign_type: "sign",
        project: projectName,
        major_nm: clientName,
        minor_nm: userName,
        officer_nm: pxdName,
        officer_mail: email,
        language: lang,
        section_data: [
          {
            section_nm: "UserSurvey01",
            section_flag: section.UserSurvey01,
          },
          {
            section_nm: "UserSurvey02",
            section_flag: section.UserSurvey02,
          },
          {
            section_nm: "UserSurvey03",
            section_flag: section.UserSurvey03,
          },
          {
            section_nm: "UserSurvey04",
            section_flag: section.UserSurvey04,
          },
        ],
        user_idx: JSON.parse(session.get("userIdx")),
      };

      console.log(sendData);

      applyChecker(checker);
      setApply(checker);
      data(sendData);
    }
  };

  return (
    <div className={classes.wrapper}>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              name="UserSurvey01"
              checked={section.UserSurvey01 === 1}
              onChange={handleChange}
            />
          }
          label="Intro"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="UserSurvey02"
              checked={section.UserSurvey02 === 1}
              onChange={handleChange}
            />
          }
          label="개인정보수집/제공"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="UserSurvey03"
              checked={section.UserSurvey03 === 1}
              onChange={handleChange}
            />
          }
          label="사례비 지급"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="UserSurvey04"
              checked={section.UserSurvey04 === 1}
              onChange={handleChange}
            />
          }
          label="비밀유지계약서"
        />
      </FormGroup>

      <a
        href="/files/사용자조사_개인정보_관련.docx"
        className={classes.download}
        download
      >
        Word 문서 다운로드
      </a>
      <a
        href="/files/사용자조사 개인정보 관련_영문.docx"
        className={classes.download}
        download
      >
        Word(English) File Download
      </a>

      <div>
        <Table aria-label="table">
          <TableBody>
            <TableRow>
              <TableCell>대상자</TableCell>
              <TableCell>
                <TextField
                  label="이름"
                  name="text-field"
                  onChange={(e) => setUserName(e.target.value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>PXD 책임자</TableCell>
              <TableCell>
                <TextField
                  label="이름"
                  name="text-field"
                  onChange={(e) => setPxdName(e.target.value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>PXD 책임자 E-Mail</TableCell>
              <TableCell>
                <TextField
                  className="email"
                  label="pxd@pxd.co.kr"
                  name="text-field"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" style={{ width: 160 }}>
                프로젝트명
              </TableCell>
              <TableCell>
                <TextField
                  label="프로젝트명"
                  name="text-field"
                  onChange={(e) => setProjectName(e.target.value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>클라이언트명</TableCell>
              <TableCell>
                <TextField
                  label="이름"
                  name="text-field"
                  onChange={(e) => setClientName(e.target.value)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div className={classes.wrapBtn}>
          <Button
            variant="contained"
            color="primary"
            disabled={apply}
            startIcon={<CreateIcon />}
            onClick={onApply}
          >
            Apply
          </Button>
        </div>
      </div>
      {Object.keys(section).map((v, i) => {
        switch (v) {
          case "UserSurvey01":
            return (
              section[v] === 1 && (
                <UserSurvey01
                  key={i}
                  mode="write"
                  userName={userName}
                  pxdName={pxdName}
                  email={email}
                  date={date}
                />
              )
            );
          case "UserSurvey02":
            return (
              section[v] === 1 && (
                <UserSurvey02
                  key={i}
                  mode="write"
                  projectName={projectName}
                  clientName={clientName}
                  date={date}
                  userName={userName}
                />
              )
            );
          case "UserSurvey03":
            return section[v] === 1 && <UserSurvey03 key={i} mode="write" />;
          case "UserSurvey04":
            return (
              section[v] === 1 && (
                <UserSurvey04
                  key={i}
                  mode="write"
                  userName={userName}
                  projectName={projectName}
                  date={date}
                />
              )
            );
          default:
            break;
        }

        // return section[v] === 1 && <Component />
      })}
      {/*{ section.UserSurvey01 === 1 && <UserSurvey01 mode="write" userName={userName} pxdName={pxdName} email={email} date={date}/> }*/}
      {/*{ section.UserSurvey02 === 1 && <UserSurvey02 mode="write" projectName={projectName} clientName={clientName} date={date} userName={userName}/> }*/}
      {/*{ section.UserSurvey03 === 1 && <UserSurvey03 mode="write"/> }*/}
      {/*{ section.UserSurvey04 === 1 && <UserSurvey04 mode="write" userName={userName} projectName={projectName} date={date}/> }*/}
    </div>
  );
};

export default UserSurveyWriteContainer;
