import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  loaderWrap: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(255, 255, 255,0.3)",
    zIndex: "1000",
  },
  loader: {
    position: "fixed",
    top: "50%",
    left: "50%",
    width: "64px",
    height: "64px",
    transform: "translate(-50%,-50%)",
    backgroundImage: "url(/loader.gif)",
    backgroundPosition: "center",
    backgroundSize: "64px",
    zIndex: "1001",
  },
}));

const Loader = () => {
  const classes = useStyles();
  return (
    <div className={classes.loaderWrap}>
      <div className={classes.loader}></div>
    </div>
  );
};

export default Loader;
