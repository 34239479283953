import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import SignaturePad from "signature_pad";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import {isEmpty} from "../../../lib/utils";
import {Button} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
	section: {
		marginTop: "30px",
		padding: "20px 0",
		lineHeight: 1.6,
		borderTop: "1px solid #333",
		"& p": {
			marginTop: "10px"
		},
		"& li li": {
			paddingLeft: "15px"
		}
	},
	title: {
		padding: "15px 0",
		fontSize: "20px",
		fontWeight: 700,
		textAlign: "center"
	},
	box: {
		margin: "15px 0",
		padding: "15px",
		border: "1px solid #333",
		"& > ul > li": {
			marginTop: "10px"
		},
	},
	bold: {
		fontWeight: 700,
		wordBreak: "break-all"
	},
	alignRight: {
		textAlign: "right"
	},
	signPad: {
		marginTop: "20px",
		textAlign: "right",
		"& .signature": {
			display: "inline-block",
			width: "335px",
			height: "200px",
			border: "1px dotted #333"
		}
	}
}));

let canvas;
let signaturePad;

const UserSurvey02 = ({mode, idx, projectName, clientName, date, userName, signImg, signImgList}) => {
	const classes = useStyles();
	const [agree, setAgree] = useState({
		agree01: (!isEmpty(signImg)) ? 'yes' : '',
		agree02: (!isEmpty(signImg)) ? 'yes' : ''
	});

	useEffect(() => {
		if (mode === 'viewer') {
			canvas = document.querySelector(`#signature${idx}`);
			signaturePad = new SignaturePad(canvas);
		}
	}, []);

	const saveSign = (e) => {
		if (agree.agree01 === 'yes' && agree.agree02 === 'yes') {
			let data = signaturePad.toDataURL('image/png');

			if (signaturePad.isEmpty()) {
				alert("서명 후 저장해주세요.");
				return false;
			}

			signImgList(1, data);
			alert('저장되었습니다.');

			let thisEl = e.target.closest('.section');
			let nextEl = thisEl.nextElementSibling;
			let nextElFlag = nextEl && thisEl.nextElementSibling.classList.contains('section');

			if (nextElFlag) {
				// const y = nextEl.getBoundingClientRect().top + window.scrollY;

				let ua = window.navigator.userAgent;
				let msie = ua.indexOf("MSIE ");

				if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
					// If Internet Explorer
					window.scrollTo(0, nextEl.offsetTop)
				} else  {
					// If another browser
					// not support ios safari -_-;;
					window.scroll({
						top: nextEl.offsetTop,
						behavior: 'smooth'
					});
				}
			}
		} else {
			alert('개인정보 수집·이용 동의 및 제 3자에게 제공하는 것에 동의가 필요합니다.')
		}
	};

	const clearSign = () => {
		signaturePad.clear();
	};

	const setSignPad = () => {
		return(
			<div className={classes.signPad}>
				<canvas id={`signature${idx}`} className="signature" width="335px" height="200px"></canvas>
				<div className={classes.wrapBtn}>
					<Button
						className="clear"
						variant="contained"
						// disabled={apply}
						startIcon={<ClearIcon/>}
						onClick={clearSign}
					>
						Clear
					</Button>&nbsp;&nbsp;&nbsp;&nbsp;
					<Button
						className="save"
						variant="contained"
						color="primary"
						// disabled={apply}
						startIcon={<CreateIcon/>}
						onClick={(e) => saveSign(e)}
					>
						Save
					</Button>
				</div>
			</div>
		)
	}

	const signView = (imgSrc) => {
		if (!isEmpty(imgSrc)) {
			return(
				<div className={classes.signPad}>
					<img src={imgSrc} className="signature" alt="사인 이미지"/>
				</div>
			)
		}
	}

	const handleChange = (e) => {
		setAgree({
			...agree,
			[e.target.name]: e.target.value
		})
	}

	return (
		<div className={`${classes.section} section`}>
			<p className={classes.title}>
				사용자조사/사용성테스트를 위한<br/>
				개인정보 수집&#183;제공 및 제3자 제공 동의서
			</p>

			<p>㈜피엑스디는 개인정보보호법 등 관련 법령상의 개인정보보호규정을 준수하며 조사 응답자의 개인정보를 수집&#183;이용하고 제3자에 제공하고 있습니다.</p>
			<p>㈜피엑스디는 “<span className={classes.bold}>{projectName}</span>” 조사를 위해 아래와 같이 개인정보를 수집&#183;이용 및 제 3자에게 제공하고자 하오니 내용을 자세히 읽으신 후 동의 여부를 결정해 주십시오.</p>

			<div className={classes.box}>
				<ul>
					<li>
						1. 수집&#183;이용하는 개인정보
						<ol>
							<li>① 응답자 성명, 나이, 성별, 거주지, 연락처 등 기타 본 조사에서 응답하는 내용</li>
							<li>② 응답자의 주민등록번호, 계좌정보, 연락처</li>
						</ol>
					</li>
					<li>
						2. 수집&#183;이용목적
						<ol>
							<li>①의 항목: “<span className={classes.bold}>{projectName}</span>”의 연구 및 조사 자료로 사용</li>
							<li>②의 항목: 응답자 조사 사례비의 세무/회계 처리 용도</li>
						</ol>
					</li>
					<li>
						3. 개인정보 보유기간
						<ol>
							<li>①의 항목: “<span className={classes.bold}>{projectName}</span>” 종료 후 3년</li>
							<li>②의 항목: 5년</li>
						</ol>
					</li>
					<li>
						4. ①의 항목에 관련한 개인정보 제3자 제공내역
						<ul>
							<li>제공받는 기관: <span className={classes.bold}>{clientName}</span></li>
							<li>제공 목적: “<span className={classes.bold}>{projectName}</span>”의 연구 및 조사 자료</li>
							<li>제공항목: 제 1항 내 ①의 항목과 동일</li>
							<li>보유기간: 제 3항 내 ①의 항목과 동일</li>
						</ul>
					</li>
					<li>
						5. ②의 항목에 관련한 개인정보 제3자 제공내역
						<ul>
							<li>제공받는 기관: 회계법인 세아</li>
							<li>제공 목적: 응답자 사례비 관련 회계/세무처리</li>
							<li>제공항목: 제 1항 내 ②의 항목과 동일</li>
							<li>보유기간: 제 3항 내 ②의 항목과 동일</li>
						</ul>
					</li>
					<li>
						6. 동의를 거부할 권리 안내
						<ul>
							<li>응답자는 위와 같이 개인정보를 제공하는 데 대한 동의를 거부할 권리가 있습니다. 그러나 동의를 거부하실 경우 해당 조사에 참여하실 수 없으며, 조사는 즉시 종료됩니다.</li>
						</ul>
					</li>
				</ul>
			</div>
			<p>위와 같이 개인정보를 수집&#183;이용 하는데 동의 하십니까?</p>
			<RadioGroup aria-label="개인정보 수집/이용 하는데 동의 하십니까?" name="agree01" value={agree.agree01} onChange={handleChange}>
				<FormControlLabel value="yes" control={<Radio />} label="네" disabled={!isEmpty(signImg) || mode !== 'viewer'} />
				<FormControlLabel value="no" control={<Radio />} label="아니오" disabled={!isEmpty(signImg) || mode !== 'viewer'} />
			</RadioGroup>

			<p>위와 같이 개인정보를 제3자에게 제공하는 것에 동의 하십니까?</p>
			<RadioGroup aria-label="개인정보를 제 3자에게 제공하는 것에 동의 하십니까?" name="agree02" value={agree.agree02} onChange={handleChange}>
				<FormControlLabel value="yes" control={<Radio />} label="네" disabled={!isEmpty(signImg) || mode !== 'viewer'} />
				<FormControlLabel value="no" control={<Radio />} label="아니오" disabled={!isEmpty(signImg) || mode !== 'viewer'} />
			</RadioGroup>

			<p className={classes.alignRight}>{date}</p>
			<p className={classes.alignRight}>본인: <span className={classes.bold}>{userName}</span>(자필필요) 인</p>

			{ mode === 'view' && signView(signImg) }
			{ mode === 'print' && signView(signImg) }
			{ mode === 'viewer' && setSignPad() }
		</div>
	)
};

export default UserSurvey02;
