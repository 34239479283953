import { combineReducers } from 'redux';
import loading from './loading';
import ui from './ui';
import Login from './Login';
import SignList from './SignList';
import User from './User'

const rootReducer = combineReducers({
	loading,
	ui,
	Login,
	SignList,
	User
});

export default rootReducer;
