import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import session from '../../lib/sessionStorage';
import {logoutBye} from "../../modules/Login";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {Button} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "fixed",
		top: 0,
		zIndex: 300,
		flexGrow: 1,
		width: "100%",
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
		"& a": {
			color: "#fff",
			fontWeight: 700,
			textDecoration: "none"
		}
	},
}));

const Header = ({isLogin, logoutBye, isAdmin}) => {
	const classes = useStyles();

	const onLogout = () => {
		session.remove('isLogin');
		session.remove('token');
		session.remove('isAdmin');
		session.remove('userIdx');
		logoutBye();
	}

	return (
		<header>
			<div className={classes.root}>
				<AppBar position="static">
					<Toolbar>
						<Typography variant="h6" className={classes.title}>
							<Link to="/">pxd Signature System</Link>
						</Typography>
						{
							isAdmin && (
								<div>
									<Button
										variant="contained"
										color="primary"
										component={Link}
										to={`/userList`}
									>
										사용자관리
									</Button>
								</div>
							)
						}
						{isLogin && (
							<div>
								<Button
									variant="outlined"
									onClick={onLogout}
								>
									Logout
								</Button>
							</div>
						)}
					</Toolbar>
				</AppBar>
			</div>
		</header>
	);
};

export default connect(
	({Login}) => ({

	}),
	{
		logoutBye
	}
)(Header);
