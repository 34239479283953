import React, {Fragment, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { getDetailView } from "../modules/SignList";
import {Button} from "@material-ui/core";
import CreateIcon from '@material-ui/icons/Create';
import UserSurveyUpdateContainer from './containers/UserSurvey/UserSurveyUpdateContainer';
import COVID19UpdateContainer from "./containers/COVID19/COVID19UpdateContainer";
import qs from 'qs';
import {isEmpty} from "../lib/utils";
import { makeStyles } from '@material-ui/core/styles';
import * as api from '../apis/SignList'

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	alignCenter: {
		'& > *': {
			margin: theme.spacing(1),
		},
		textAlign: "center"
	}
}));

const Update = ({location, getDetailView, detailView, history}) => {
	const classes = useStyles();
	const [data, setData] = useState(null);

	useEffect( () => {
		let params = location.search;
		getDetailView(params);
	}, []);

	const switchViewContainer = () => {
		let queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

		if (isEmpty(detailView)) {
			return (
				<div>
					no data
				</div>
			)
		} else {
			return (
				<Fragment>
					{ queryParams.type === 'sign' && <UserSurveyUpdateContainer mode="view" data={(value) => setData(value)} detailView={detailView} history={history}/> }
					{ queryParams.type === 'survey' && <COVID19UpdateContainer mode="view" data={(value) => setData(value)} detailView={detailView} history={history}/> }
				</Fragment>
			)
		}
	}

	const onUpdate = () => {
		if(data) {
			api.postUpdateSign(data).then((res) => {
				if (res.data.result) {
					history.push('/view?type='+data.sign_type+'&idx='+data.pid);
				}
			});
		}else{
			alert('APPLY 를 눌러주세요')
		}
	}

	return (
		<div>
			{ switchViewContainer() }
			<div className={classes.alignCenter}>
				{/*<Button*/}
				{/*	variant="contained"*/}
				{/*	startIcon={<VisibilityIcon/>}*/}
				{/*	onClick={onPreview}*/}
				{/*>*/}
				{/*	Preview*/}
				{/*</Button>*/}
				<Button
					variant="contained"
					color="primary"
					// disabled={!applyChecker}
					startIcon={<CreateIcon/>}
					onClick={onUpdate}
				>
					Update
				</Button>
			</div>
		</div>
	)
}

export default connect(
	({ SignList, loading }) => ({
		detailView: SignList.detailView,
		loading: loading
	}),
	{
		getDetailView,
	}
)(Update);
