import React, {useState, useEffect} from 'react';

import {connect} from 'react-redux';
import * as utils from '../lib/utils';
import session from '../lib/sessionStorage';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import AuthRoute from './AuthRoute';
// import Main from '../views/Main';
import PageNotFound from '../views/PageNotFound';
import Login from '../views/Login';
import Join from '../views/Join';
import List from '../views/List';
import Write from '../views/Write';
import View from '../views/View';
import Update from '../views/Update'
import Viewer from '../views/Viewer';
import UserManagement from '../views/UserManagement'
import Complete from "../views/containers/Complete";
import Error from '../views/Error';
import Header from '../components/layouts/Header';
import Footer from '../components/layouts/Footer';
import ScrollTop from "./ScrollTop";

const useStyles = makeStyles((theme) => ({
	wrap: {
		margin: "0 auto",
		padding: "100px 20px 150px",
		maxWidth: '1000px'
	}
}));

const AppRouter = ({ ui, loginStatus }) => {
	const classes = useStyles();

	let isLogin = false;
	let isAdmin = false

	if (!utils.isEmpty(loginStatus)) {
		if(loginStatus.result.is_apply >= 1){
			let status = loginStatus.result.status;
			if (status === 'success') {
				isLogin = true;
			}

			if(loginStatus.result.is_admin >= 1){
				isAdmin = true;
			}

		}
	} else if (!utils.isEmpty(session.get('isLogin'))) {
		isLogin = JSON.parse(session.get('isLogin'));
	}else {

	}

	return (
		<Router>
			<ScrollTop/>
			{
				ui.header &&
				<Header isLogin={isLogin} isAdmin={isAdmin} />
			}
			<div className={classes.wrap}>
			<Switch>
				{/*<Route path="/" component={Main} exact={true}/>*/}
				<AuthRoute authenticated={isLogin} path="/" exact={true} render={ props => <List {...props} /> }/>
				<Route path="/login" render={ props => <Login isLogin={isLogin} {...props} /> }/>
				<Route path="/join" render={ props => <Join isLogin={isLogin} {...props} /> }/>
				<Route path="/viewer/:key" render={ props => <Viewer {...props} /> }/>
				<Route path="/complete" render={ props => <Complete {...props} /> }/>
				{/*<Route path="/viewer" component={Viewer}/>*/}

				{/* 로그인 권한이 필요 */}
				{/*<AuthRoute authenticated={isLogin} path="/template" render={ props => <Template {...props} /> }/>*/}
				<AuthRoute authenticated={isLogin} path="/list" render={ props => <List {...props} /> }/>
				<AuthRoute authenticated={isLogin} path="/write" render={ props => <Write {...props} /> }/>
				<AuthRoute authenticated={isLogin} path="/edit" render={ props => <Write {...props} /> }/>
				<AuthRoute authenticated={isLogin} path="/view" render={ props => <View {...props} /> }/>
				<AuthRoute authenticated={isLogin} path="/update" render={ props => <Update {...props} /> }/>
				
				<AuthRoute authenticated={isLogin} path="/userList" render={ props => <UserManagement {...props} /> }/>

				

				{/* Error */}
				<Route path="/error" render={ props => <Error {...props} /> }/>

				{/* PageNotFound : 404 */}
				<Route component={PageNotFound}/>
			</Switch>
			</div>
			{
				ui.footer &&
				<Footer />
			}
		</Router>
	);
};

export default connect(
	state => ({
		ui: state.ui,
		loginStatus: state.Login.data,
	}),
	{

	}
)(AppRouter);
