import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {login} from '../modules/Login';
import * as utils from "../lib/utils";
import session from '../lib/sessionStorage';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

const Login = ({ history, login, loginStatus, isLogin }) => {
	const classes = useStyles();
	const [id, setId] = useState(null);
	const [pw, setPw] = useState(null);

	useEffect( () => {
		if (isLogin) {
			history.push('/list');
		}
	}, []);

	useEffect(() => {
		// Submit Login Result
		if (!utils.isEmpty(loginStatus)) {
			let status = loginStatus.result.status;

			if (status === 'success') {
				if(loginStatus.result.is_apply == 0){
					alert('관리자 승인 대기중 입니다.')
				}else{
					let isLogin = true;
					let token = loginStatus.result.jsontoken;
					session.set('isLogin', isLogin);
					session.set('token', token);
					session.set('isAdmin', loginStatus.result.is_admin);
					session.set('userIdx', loginStatus.result.user_idx);
	
					history.push('/list');
					// history.push('/userList');
				}
			} else {
				// Login Failed
				alert('ID 또는 Password를 다시 확인해주세요.');

				let inputId = document.querySelector('[name=userid]');
				let inputPw = document.querySelector('[name=password]')

				inputPw.value = '';
				inputId.value = '';
				inputId.focus();

				setId(null);
				setPw(null);
			}
		}
	}, [loginStatus])

	const submitLogin = async (e) => {
		e.preventDefault();

		let data = {
			user_id: id,
			user_passwd: pw
		};

		await login(data);
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Sign in
				</Typography>
				<form className={classes.form} noValidate onSubmit={submitLogin}>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="user_id"
						label="User ID"
						name="userid"
						autoComplete="id"
						autoFocus
						onChange={(e) => setId(e.target.value)}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						autoComplete="current-password"
						onChange={(e) => setPw(e.target.value)}
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
						// onClick={submitLogin}
					>
						Sign In
					</Button>
					<Button
						fullWidth
						variant="contained"
						color="primary"
						component={Link}
						to={`/join`}
					>
						Sign Up
					</Button>
				</form>
			</div>
		</Container>
	);
};

export default connect(
	(state) => ({
		loginStatus: state.Login.data,
	}),
	{
		login,
	}
)(Login);
