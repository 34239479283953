import React from 'react';
import COVID19QNA from "../../../components/templates/COVID19/COVID19QNA";

const COVID19ViewerContainer = ({mode, detailView, data, signImg}) => {
	const addSignImg = (img) => {
		signImg(img);
	}

	const viewerData = (value) => {
		data(value);
	}

	return(
		<div>
			<COVID19QNA mode={mode} detailView={detailView} propsData={viewerData} signImgList={addSignImg} />
		</div>
	)
};

export default COVID19ViewerContainer;
