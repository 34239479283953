import request from "./request";

export const getUserList = () => {
	return request({
		url: '/users/getUserList',
		method: 'get',
	})
}

export const applyUser = (data) => {
	return request ({
		url: '/users/applyUser',
		method:'post',
		data:data
	})
}

export const addUser = (data) => {
	return request ({
		url: '/users/addUser',
		method:'post',
		data:data
	})
}