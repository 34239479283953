import React, {useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import pxdStamp from '../../../assets/images/pxdStamp.png'
import SignaturePad from "signature_pad";
import {isEmpty} from "../../../lib/utils";
import {Button} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";

const useStyles = makeStyles((theme) => ({
	section: {
		marginTop: "30px",
		padding: "20px 0",
		lineHeight: 1.6,
		borderTop: "1px solid #333",
		"& p": {
			marginTop: "10px"
		},
		"& > ul > li": {
			marginTop: "10px"
		},
		"& li": {
			paddingLeft: "15px"
		}
	},
	title: {
		padding: "15px 0",
		fontSize: "20px",
		fontWeight: 700,
		textAlign: "center"
	},
	box: {
		margin: "15px 0",
		padding: "15px",
		border: "1px solid #333"
	},
	bold: {
		fontWeight: 700
	},
	alignRight: {
		textAlign: "right"
	},
	signPad: {
		marginTop: "20px",
		textAlign: "right",
		"& .signature": {
			display: "inline-block",
			width: "335px",
			height: "200px",
			border: "1px dotted #333"
		}
	}
}));

let canvas;
let signaturePad;

const UserSurvey04 = ({mode, idx, userName, projectName, date, signImg, signImgList}) => {
	const classes = useStyles();

	useEffect(() => {
		if (mode === 'viewer') {
			canvas = document.querySelector(`#signature${idx}`);
			signaturePad = new SignaturePad(canvas);
		}
	}, []);

	const saveSign = () => {
		let data = signaturePad.toDataURL('image/png');

		if (signaturePad.isEmpty()) {
			alert("서명 후 저장해주세요.");
			return false;
		}

		signImgList(3, data);
		alert('저장되었습니다.');
	};

	const clearSign = () => {
		signaturePad.clear();
	};

	const setSignPad = () => {
		return(
			<div className={classes.signPad}>
				<canvas id={`signature${idx}`} className="signature" width="335px" height="200px"></canvas>
				<div className={classes.wrapBtn}>
					<Button
						className="clear"
						variant="contained"
						// disabled={apply}
						startIcon={<ClearIcon/>}
						onClick={clearSign}
					>
						Clear
					</Button>&nbsp;&nbsp;&nbsp;&nbsp;
					<Button
						className="save"
						variant="contained"
						color="primary"
						// disabled={apply}
						startIcon={<CreateIcon/>}
						onClick={saveSign}
					>
						Save
					</Button>
				</div>
			</div>
		)
	}

	const signView = (imgSrc) => {
		if (!isEmpty(imgSrc)) {
			return(
				<div className={classes.signPad}>
					<img src={imgSrc} className="signature" alt="사인 이미지"/>
				</div>
			)
		}
	}

	return(
		<div className={`${classes.section} section`}>
			<p className={classes.title}>비밀유지계약서</p>
			<p><span className={classes.bold}>{userName}</span>(이하 “대상자”)와 <span className={classes.bold}>㈜피엑스디</span>(이하 “제공자”)은 <span className={classes.bold}>“{projectName}”</span>에 관련하여 다음과 같이 비밀유지계약을 체결한다.</p>

			<ul>
				<li>
					<span className={classes.bold}>제1조(목적)</span><br/>
					본 계약은 “제공자”가 권리를 가지고 있는 <span className={classes.bold}>“{projectName}”</span>에 관련한 내용을 “대상자”에게 설명 및 제시함에 있어서 양 당사자의 관련 비밀정보를 보호하기 위함을 목적으로 한다.
				</li>
				<li>
					<span className={classes.bold}>제2조(적용정보)</span><br/>
					<ol>
						<li>
							①	 본 계약에 따라 “제공자”가 “대상자”에게 제공하는 비밀정보는 다음 각 호와 같다.
							<ol>
								<li>1.	“제공자”가 제시하는 영상, 이미지, 문서자료</li>
								<li>2.	“제공자”가 제시하는 문자, 영상, 음성 질문 등의 내용</li>
								<li>3.	“제공자”가 보안을 요구하는 기타 모든 자료</li>
							</ol>
						</li>
						<li>
							②	 전1항에 규정한 각 당사자의 비밀정보를 합하여 본 계약상의 비밀정보라고 한다.
						</li>
					</ol>
				</li>
				<li>
					<span className={classes.bold}>제3조(비밀정보의 사용 및 반출제한)</span><br/>
					<ol>
						<li>①	 비밀정보를 제공받은 “대상자”는 설문에 대한 답변, 인터뷰의 범위에서만 해당 정보를 사용할 수 있다.</li>
						<li>②	비밀정보를 제공받은 “대상자”는 해당 정보를 복사, 녹음, 녹화, 제3자 촬영, 스크린 세이브 등의 방법으로 정보를 별도 저장하거나 외부로 반출해서는 안된다.</li>
					</ol>
				</li>
				<li>
					<span className={classes.bold}>제4조(비밀유지기간)</span><br/>
					각 당사자는 상대방이 비밀정보를 제공한 날로부터 3년 동안 본 계약의 규정에 따라 해당 비밀정보를 보호하여야 한다.
				</li>
				<li>
					<span className={classes.bold}>제5조(주의의무 정도)</span><br/>
					비밀정보를 제공받은 당사자는 자신의 비밀 정보를 보호하기 위해 기울이는 주의의무와 동일한 정도의 주의의무를 가지고 해당 비밀정보를 보호하여야 한다.
				</li>
				<li>
					<span className={classes.bold}>제6조(비밀정보의 예외)</span><br/>
					다음 각호에 해당하는 정보는 비밀정보로 취급하지 아니한다.
					<ol>
						<li>1.	비밀정보 제공이전부터 제공받은 당사자가 보유하고 있던 정보</li>
						<li>2.	비밀정보를 제공받은 당사자의 과실없이 공지의 사실로 된 정보</li>
						<li>3.	비밀정보를 제공받은 당사자가 적법하게 제3자로부터 제공받은 정보</li>
						<li>4.	비밀정보를 제공한 당사자가 사전에 유출을 허락한 정보</li>
					</ol>
				</li>
				<li>
					<span className={classes.bold}>제7조(보증)</span><br/>
					비밀정보를 제공하는 당사자는 적법하게 비밀을 제공할 권리를 갖고 있음을 보증한다.
				</li>
				<li>
					<span className={classes.bold}>제8조(지적소유권)</span><br/>
					본 계약 체결 전에 각 당사자가 소유하고 있던 지적재산권은 원래의 당사자에게 그대로 귀속되며, 본 계약이 상대방에게 지적재산권을 허여 또는 허락하는 것으로 해석하지 않는다.
				</li>
				<li>
					<span className={classes.bold}>제9조(자료의 반환)</span><br/>
					각 당사자가 상대방에게 제공한 제반 자료, 장비, 서류, 기타 정보에 대해 반환 요청을 할 경우에는 그 사본과 더불어 해당 자료 및 정보를 즉시 반환하여야 한다.
				</li>
				<li>
					<span className={classes.bold}>제10조(손해배상)</span><br/>
					“대상자” 또는 “대상자”의 담당자 및 종업원이 비밀사항을 제3자에게 개시 또는 누설하여 “제공자”에게 손해를 끼친 경우 손해 일체를 배상한다.
				</li>
				<li>
					<span className={classes.bold}>제11조(기타 의무의 부과)</span><br/>
					본 계약은 당사자들에게 특정 기술, 용역, 제조물에 관한 판매, 구입, 실시권 허여, 양도 및 처분 등 어떠한 의무도 부과하지 아니한다.
				</li>
				<li>
					<span className={classes.bold}>제12조(양도)</span><br/>
					본 계약의 권리와 의무는 상대방의 사전 서면 동의 없이는 제3자에게 양도되지 아니한다.
				</li>
				<li>
					<span className={classes.bold}>제13조(계약의 수정)</span><br/>
					본 계약의 수정이나 조항의 첨가는 양 당사자간의 정당한 대표자에 의한다.
				</li>
				<li>
					<span className={classes.bold}>제14조(관할법원)</span><br/>
					본 계약과 관련된 모든 분쟁 등은 “대상자”의 본점이 소재한 곳의 법원에서 관할한다.
				</li>
			</ul>

			<p className={classes.alignRight}>{date}</p>

			<p className={classes.alignRight}>“대상자”</p>
			<p className={classes.alignRight}><span className={classes.bold}>{userName}</span>(자필필요)  (인)</p>
			{ mode === 'view' && signView(signImg) }
			{ mode === 'print' && signView(signImg) }
			{ mode === 'viewer' && setSignPad() }

			<p className={classes.alignRight}>“제공자”</p>
			<p className={classes.alignRight}>
				<span className={classes.bold}>㈜피엑스디</span>   (인)<br/>
				<img src={pxdStamp} alt="피엑스디 직인" />
			</p>
		</div>
	)
};

export default UserSurvey04;
