import React from 'react';
import AppRouter from './router';
import {hot} from 'react-hot-loader';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import themeJson from './assets/styles/theme.json';

const theme = createMuiTheme(themeJson);

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <AppRouter/>
        </ThemeProvider>
    );
};

export default hot(module)(App);
