import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";

import "./assets/styles/reset.css";
import "./assets/styles/NotoSansKR.css";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./modules";
import { createLogger } from "redux-logger";
import ReduxThunk from "redux-thunk";

import dotenv from "dotenv";
dotenv.config();

const logger = createLogger();
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(logger, ReduxThunk))
);

let debug = (function () {
  let oldConsoleLog = null;
  let logger = {};

  logger.on = function () {
    if (oldConsoleLog == null) return;
    window["console"]["log"] = oldConsoleLog;
    window["console"]["warn"] = oldConsoleLog;
    window["console"]["error"] = oldConsoleLog;
  };

  logger.off = function () {
    oldConsoleLog = console.log;
    window["console"]["log"] = function () {};
    window["console"]["warn"] = function () {};
    window["console"]["error"] = function () {};
  };

  return logger;
})();

if (process.env.NODE_ENV === "development") {
  debug.on();
} else {
  debug.off();
}

console.log(process.env.REACT_APP_API_URL_BASE);

// Element.closest IE11 Polyfill
if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    var el = this;

    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
