import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getList } from "../modules/SignList";
import { isEmpty } from "../lib/utils";
import ClipboardJS from "clipboard";
import * as utils from "../lib/utils";
import session from "../lib/sessionStorage";

import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import PrintIcon from "@material-ui/icons/Print";
// import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import SendIcon from "@material-ui/icons/Send";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import LinkIcon from "@material-ui/icons/Link";
import { Button } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Loader from "../components/loader";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;
  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(
  idx,
  type,
  projectName,
  language,
  officer_nm,
  name,
  state,
  link
) {
  return { idx, type, projectName, language, officer_nm, name, state, link };
}

let rows = [];

// const rows = [
// 	createData('보안서약서', 'ㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁ', '류중열', false),
// 	createData('보안서약서', 'ㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁ', '류중열', true),
// 	createData('보안서약서', 'ㅁㅁㅁㅁㅁ', '류중열', false),
// 	createData('보안서약서', 'ㅁㅁㅁㅁㅁ', '류중열', true),
// 	createData('보안서약서', 'ㅁㅁㅁㅁㅁ', '류중열', false),
// 	createData('보안서약서', 'ㅁㅁㅁㅁㅁ', '류중열', false),
// ];//.sort((a, b) => (a.type < b.type ? -1 : 1));

const useStyles2 = makeStyles((theme) => ({
  wrapTable: {
    margin: "20px auto 0",
    maxWidth: "1400px",
  },
  table: {
    minWidth: 500,
  },
  tableHead: {
    backgroundColor: "#333",
    "& th": {
      color: "#fff",
    },
  },
  tr: {
    "&:hover": {
      backgroundColor: "#efefef",
    },
  },
  cursor: {
    cursor: "pointer",
  },
  link: {
    color: "#000",
    textDecoration: "none",
  },
  wrapBtn: {
    marginTop: "20px",
    textAlign: "right",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  wrapSearch: {
    textAlign: "right",
  },
  wrapSearchType: {
    display: "inline-block",
    marginRight: "15px",
    lineHeight: "82px",
  },
  wrapSearchText: {
    display: "inline-block",
    marginRight: "15px",
    lineHeight: "64px",
  },
  wrapSearchBtn: {
    display: "inline-block",
    lineHeight: "64px",
  },
}));

const clipboard = new ClipboardJS(".clipboard-btn");
const onPublicLink = () => {
  clipboard.on("success", function () {
    alert("URL이 복사되었습니다.");
  });
  clipboard.on("error", function () {
    alert("URL 복사 실패. 다시 시도해주세요.");
  });
};
onPublicLink();

const List = ({ list, getList }) => {
  const classes = useStyles2();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchType, setSearchType] = useState("project");
  const [searchText, setSearchText] = useState("");

  const protocol = `${window.location.protocol}//`;
  const hostName = window.location.hostname;
  const port = !isEmpty(window.location.port) ? `:${window.location.port}` : "";

  useEffect(() => {
    let getSignList = async () => {
      if (
        !utils.isEmpty(session.get("isAdmin")) &&
        JSON.parse(session.get("isAdmin")) == 1
      ) {
        await getList("");
      } else {
        let queryParams = `?search_type=${searchType}&search_text=${searchText}&user_idx=${JSON.parse(
          session.get("userIdx")
        )}&is_admin=${JSON.parse(session.get("isAdmin"))}`;
        getList(queryParams);
      }
    };
    getSignList();
  }, []);

  useEffect(() => {
    console.log("list", list);
    if (!isEmpty(list)) {
      let data = list.result;
      rows = [];
      data.forEach((v, i) => {
        rows.push(
          createData(
            v.idx,
            v.sign_type,
            v.project,
            v.language,
            v.officer_nm,
            v.minor_nm,
            v.sign_status,
            v.url_key
          )
        );
      });
    }
  }, [list]);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onPrint = (key) => {
    window.open(`/viewer/${key}`, "_print");
  };

  const onSearch = () => {
    let queryParams = `?search_type=${searchType}&search_text=${searchText}&user_idx=${JSON.parse(
      session.get("userIdx")
    )}&is_admin=${JSON.parse(session.get("isAdmin"))}`;
    getList(queryParams);
  };

  const onSearchEnter = (e) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };

  return (
    <div className={classes.wrapTable}>
      <div className={classes.wrapSearch}>
        <div className={classes.wrapSearchType}>
          <FormControl className={classes.formControl}>
            <InputLabel>Type</InputLabel>
            <Select
              value={searchType}
              onChange={(e) => setSearchType(e.target.value)}
            >
              <MenuItem value="project">프로젝트명</MenuItem>
              <MenuItem value="officer_nm">담당자</MenuItem>
              <MenuItem value="minor_nm">대상자</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={classes.wrapSearchText}>
          <TextField
            label="검색어"
            onChange={(e) => setSearchText(e.target.value)}
            onKeyPress={onSearchEnter}
          />
        </div>
        <div className={classes.wrapSearchBtn}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}
            onClick={onSearch}
          >
            검색
          </Button>
        </div>
      </div>
      {isEmpty(list) ? (
        <Loader />
      ) : (
        <Fragment>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              aria-label="custom pagination table"
            >
              <TableHead>
                <TableRow className={classes.tableHead}>
                  <TableCell align="center">No</TableCell>
                  <TableCell align="center">프로젝트명</TableCell>
                  <TableCell align="center">언어</TableCell>
                  <TableCell align="center">pxd 담당자</TableCell>
                  <TableCell align="center">대상자</TableCell>
                  <TableCell align="center">상태</TableCell>
                  <TableCell align="center">프린트</TableCell>
                  {/*<TableCell align="center">PDF</TableCell>*/}
                  <TableCell align="center">링크</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? rows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rows
                ).map((row, index) => (
                  <TableRow className={classes.tr} key={index}>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ width: 100 }}
                    >
                      {/*{row.type}*/}
                      {row.idx}
                    </TableCell>
                    <TableCell align="center">
                      <Link
                        to={`/view?type=${row.type}&idx=${row.idx}`}
                        className={classes.link}
                      >
                        {row.projectName}
                      </Link>
                    </TableCell>
                    <TableCell
                      style={{ width: 60, wordBreak: "keep-all" }}
                      align="center"
                    >
                      {row.language}
                    </TableCell>
                    <TableCell
                      style={{ width: 100, wordBreak: "keep-all" }}
                      align="center"
                    >
                      {row.officer_nm}
                    </TableCell>
                    <TableCell
                      style={{ width: 100, wordBreak: "keep-all" }}
                      align="center"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell style={{ width: 60 }} align="center">
                      {row.state === "complete" && (
                        <CheckCircleOutlineIcon color="primary" />
                      )}
                      {row.state !== "complete" && <SendIcon />}
                    </TableCell>
                    <TableCell style={{ width: 50 }} align="center">
                      <IconButton
                        onClick={() => onPrint(`${row.link}?mode=print`)}
                      >
                        <PrintIcon />
                      </IconButton>
                    </TableCell>
                    {/*<TableCell style={{ width: 50 }} align="center">*/}
                    {/*	<IconButton>*/}
                    {/*		<PictureAsPdfIcon />*/}
                    {/*	</IconButton>*/}
                    {/*</TableCell>*/}
                    <TableCell style={{ width: 50 }} align="center">
                      <IconButton
                        className="clipboard-btn"
                        data-clipboard-text={`${protocol}${hostName}${port}/viewer/${row.link}`}
                      >
                        <LinkIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell align="center" colSpan={7}></TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={6}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <div className={classes.wrapBtn}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CreateIcon />}
              component={Link}
              to={`/write`}
            >
              New
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default connect(
  ({ SignList, loading }) => ({
    list: SignList.list,
    loading: loading,
  }),
  {
    getList,
  }
)(List);
