import React from "react"
import {Route, Redirect} from "react-router-dom"

function AuthRoute({authenticated, component: Component, render, ...rest}) {
	return (
		<Route
			render={(props) =>
				authenticated ? (
					render ? (
						render(props)
					) : (
						<Component {...props} />
					)
				) : (
					<Redirect to={{pathname: "/login", state: {from: props.location}}}/>
				)
			}
			{...rest}
		/>
	)
}

export default AuthRoute;
