import React, {useEffect, useState} from 'react';
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";
import 'moment/locale/ko';
import SignaturePad from "signature_pad";
import {isEmpty} from "../../../lib/utils";
import {Button} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";

const useStyles = makeStyles((theme) => ({
	wrapper: {
		padding: "10px"
	},
	section: {
		marginTop: "30px",
		padding: "20px 0",
		lineHeight: 1.6,
		borderTop: "1px solid #333",
		"& p": {
			marginTop: "10px"
		},
		"& > ol > li": {
			marginTop: "20px",
			padding: "0 20px"
		},
	},
	title: {
		padding: "15px 0",
		fontSize: "20px",
		fontWeight: 700,
		textAlign: "center"
	},
	alignCenter: {
		textAlign: "center"
	},
	alignRight: {
		textAlign: "right"
	},
	big: {
		fontSize: "30px"
	},
	bold: {
		fontWeight: 700
	},
	row: {
		display: "flex",
		flexWrap: "wrap",
		flexDirection: "row",
		justifyContent: "space-around",
		maxWidth: "300px"
	},
	wrapBtn: {
		padding: "10px",
		textAlign: "right"
	},
	signPad: {
		marginTop: "20px",
		textAlign: "right",
		"& .signature": {
			display: "inline-block",
			width: "335px",
			height: "200px",
			border: "1px dotted #333"
		}
	}
}));

let canvas;
let signaturePad;

const COVID19QNA = ({mode, userName, detailView, date, propsData, signImgList}) => {
	const classes = useStyles();
	const _userName = (isEmpty(detailView)) ? userName : detailView.results.minor_nm;
	const _date = (isEmpty(detailView)) ? date : moment(detailView.results.create_date).format('LL');

	const round = (isEmpty(detailView) || isEmpty(detailView.results.round)) ? 7 : parseInt(detailView.results.round, 10);
	let _6th;
	let _7th;
	switch(round) {
		case 6:
			_6th = {
				a01: (isEmpty(detailView)) ? null : (mode !== 'viewer') ? detailView.results.question[0].answer : null,
				a02: (isEmpty(detailView)) ? null : (mode !== 'viewer') ? detailView.results.question[1].answer : null,
				a03: (isEmpty(detailView)) ? null : (mode !== 'viewer') ? detailView.results.question[2].answer : null,
				a04: (isEmpty(detailView)) ? null : (mode !== 'viewer') ? detailView.results.question[3].answer : null,
				a05: (isEmpty(detailView)) ? null : (mode !== 'viewer') ? detailView.results.question[4].answer : null,
				a06: (isEmpty(detailView)) ? null : (mode !== 'viewer') ? detailView.results.question[5].answer : null,
			};
			break;
		case 7:
			_7th = {
				a01: (isEmpty(detailView)) ? null : (mode !== 'viewer') ? detailView.results.question[0].answer : null,
				a02: (isEmpty(detailView)) ? null : (mode !== 'viewer') ? detailView.results.question[1].answer : null,
				a03: (isEmpty(detailView)) ? null : (mode !== 'viewer') ? detailView.results.question[2].answer : null,
				a07: (isEmpty(detailView)) ? null : (mode !== 'viewer') ? detailView.results.question[3].answer : null,
				a08: (isEmpty(detailView)) ? null : (mode !== 'viewer') ? detailView.results.question[4].answer : null,
			};
			break;
	}

	const _default = ['', '', '', '', '', '', _6th, _7th];


	const [answer, setAnswer] = useState(_default[round]);
	const signImg = (isEmpty(detailView)) ? null : detailView.results.sign_img;
	let isChecker = false;

	useEffect(() => {
		if (mode === 'viewer') {
			canvas = document.querySelector(`#signature`);
			signaturePad = new SignaturePad(canvas);
		}
	}, []);

	useEffect(() => {
		let _6th;
		let _7th;

		switch (round) {
			case 6:
				_6th = [
					{
						answer_idx: (isEmpty(detailView)) ? '' : detailView.results.question[0].answer_idx,
						answer: answer.a01
					},
					{
						answer_idx: (isEmpty(detailView)) ? '' : detailView.results.question[1].answer_idx,
						answer: answer.a02
					},
					{
						answer_idx: (isEmpty(detailView)) ? '' : detailView.results.question[2].answer_idx,
						answer: answer.a03
					},
					{
						answer_idx: (isEmpty(detailView)) ? '' : detailView.results.question[3].answer_idx,
						answer: answer.a04
					},
					{
						answer_idx: (isEmpty(detailView)) ? '' : detailView.results.question[4].answer_idx,
						answer: answer.a05
					},
					{
						answer_idx: (isEmpty(detailView)) ? '' : detailView.results.question[5].answer_idx,
						answer: answer.a06
					},
				];
				break;
			case 7:
				_7th = [
					{
						answer_idx: (isEmpty(detailView)) ? '' : detailView.results.question[0].answer_idx,
						answer: answer.a01
					},
					{
						answer_idx: (isEmpty(detailView)) ? '' : detailView.results.question[1].answer_idx,
						answer: answer.a02
					},
					{
						answer_idx: (isEmpty(detailView)) ? '' : detailView.results.question[2].answer_idx,
						answer: answer.a03
					},
					{
						answer_idx: (isEmpty(detailView)) ? '' : detailView.results.question[3].answer_idx,
						answer: answer.a07
					},
					{
						answer_idx: (isEmpty(detailView)) ? '' : detailView.results.question[4].answer_idx,
						answer: answer.a08
					},
				];
				break;
			default:
				break;
		}

		let _default = ['', '', '', '', '', '', _6th, _7th];

		let sendData = _default[round];

		sendData.forEach((v, i) => {
			if (isEmpty(v.answer)) {
				isChecker = true;
			}
		});

		if (!isChecker) {
			propsData(sendData);
		}
	}, [answer]);

	const handleChange = (e) => {
		setAnswer({
			...answer,
			[e.target.name]: parseInt(e.target.value, 10)
		});
	}

	const saveSign = () => {
		let data = signaturePad.toDataURL('image/png');

		if (signaturePad.isEmpty()) {
			alert("서명 후 저장해주세요.");
			return false;
		}
		signImgList(data);
		alert('저장되었습니다.');
	};

	const clearSign = () => {
		signaturePad.clear();
	};

	const setSignPad = () => {
		return(
			<div className={classes.signPad}>
				<canvas id={`signature`} className="signature" width="335px" height="200px"></canvas>
				<div className={classes.wrapBtn}>
					<Button
						className="clear"
						variant="contained"
						// disabled={signFlag}
						startIcon={<ClearIcon/>}
						onClick={clearSign}
					>
						Clear
					</Button>&nbsp;&nbsp;&nbsp;&nbsp;
					<Button
						className="save"
						variant="contained"
						color="primary"
						// disabled={signFlag}
						startIcon={<CreateIcon/>}
						onClick={saveSign}
					>
						Save
					</Button>
				</div>
			</div>
		)
	}

	const signView = (imgSrc) => {
		if (!isEmpty(imgSrc)) {
			return(
				<div className={classes.signPad}>
					<img src={imgSrc} className="signature" alt="사인 이미지"/>
				</div>
			)
		}
	}

	const QNA6th = () => {
		return(
			<ol>
				<li>
					1.	현재 37.5℃ 이상의 발열, 기침, 목아픔 등의 증상이 있습니까?
					<RadioGroup className={classes.row} aria-label="covid19-question" name="a01" value={answer.a01} onChange={handleChange}>
						<FormControlLabel value={1} control={<Radio />} label="네" disabled={(mode !== 'viewer') && true} />
						<FormControlLabel value={2} control={<Radio />} label="아니오" disabled={(mode !== 'viewer') && true} />
					</RadioGroup>
				</li>
				<li>
					2.	현재 질병관리본부, 코로나 중앙관리대책본부 등에서 연락 받은 관리대상자입니까?
					<RadioGroup className={classes.row} aria-label="covid19-question" name="a02" value={answer.a02} onChange={handleChange}>
						<FormControlLabel value={1} control={<Radio />} label="네" disabled={(mode !== 'viewer') && true} />
						<FormControlLabel value={2} control={<Radio />} label="아니오" disabled={(mode !== 'viewer') && true} />
					</RadioGroup>
				</li>
				<li>
					3.	현재 코로나-19에 대한 능동적 감시대상자입니까?
					<RadioGroup className={classes.row} aria-label="covid19-question" name="a03" value={answer.a03} onChange={handleChange}>
						<FormControlLabel value={1} control={<Radio />} label="네" disabled={(mode !== 'viewer') && true} />
						<FormControlLabel value={2} control={<Radio />} label="아니오" disabled={(mode !== 'viewer') && true} />
					</RadioGroup>
				</li>
				<li>
					4.	지난 4월 24일 ~ 5월 10일 중 서울 이태원 지역의 클럽, 식당 등의 사업장 방문자이거나, 방문자의 가족 혹은 밀접접촉자 입니까?
					<RadioGroup className={classes.row} aria-label="covid19-question" name="a04" value={answer.a04} onChange={handleChange}>
						<FormControlLabel value={1} control={<Radio />} label="네" disabled={(mode !== 'viewer') && true} />
						<FormControlLabel value={2} control={<Radio />} label="아니오" disabled={(mode !== 'viewer') && true} />
					</RadioGroup>
				</li>
				<li>
					5.	지난 4월 24일 ~ 5월 10일 중 서울 홍대/신촌 지역의 클럽, 식당 등의 사업장 방문자이거나, 방문자의 가족 혹은 밀접접촉자 입니까?
					<RadioGroup className={classes.row} aria-label="covid19-question" name="a05" value={answer.a05} onChange={handleChange}>
						<FormControlLabel value={1} control={<Radio />} label="네" disabled={(mode !== 'viewer') && true} />
						<FormControlLabel value={2} control={<Radio />} label="아니오" disabled={(mode !== 'viewer') && true} />
					</RadioGroup>
				</li>
				<li>
					6.	현재까지 발표된 확진자와 동선이 겹치는 경우가 있습니까?
					<RadioGroup className={classes.row} aria-label="covid19-question" name="a06" value={answer.a06} onChange={handleChange}>
						<FormControlLabel value={1} control={<Radio />} label="네" disabled={(mode !== 'viewer') && true} />
						<FormControlLabel value={2} control={<Radio />} label="아니오" disabled={(mode !== 'viewer') && true} />
					</RadioGroup>
				</li>
			</ol>
		)
	};

	const QNA7th = () => {
		return(
			<ol>
				<li>
					1.	현재 37.5℃ 이상의 발열, 기침, 목아픔 등의 증상이 있습니까?
					<RadioGroup className={classes.row} aria-label="covid19-question" name="a01" value={answer.a01} onChange={handleChange}>
						<FormControlLabel value={1} control={<Radio />} label="네" disabled={(mode !== 'viewer') && true} />
						<FormControlLabel value={2} control={<Radio />} label="아니오" disabled={(mode !== 'viewer') && true} />
					</RadioGroup>
				</li>
				<li>
					2.	현재 질병관리본부, 코로나 중앙관리대책본부 등에서 연락 받은 관리대상자입니까?
					<RadioGroup className={classes.row} aria-label="covid19-question" name="a02" value={answer.a02} onChange={handleChange}>
						<FormControlLabel value={1} control={<Radio />} label="네" disabled={(mode !== 'viewer') && true} />
						<FormControlLabel value={2} control={<Radio />} label="아니오" disabled={(mode !== 'viewer') && true} />
					</RadioGroup>
				</li>
				<li>
					3.	현재 코로나-19에 대한 능동적 감시대상자입니까?
					<RadioGroup className={classes.row} aria-label="covid19-question" name="a03" value={answer.a03} onChange={handleChange}>
						<FormControlLabel value={1} control={<Radio />} label="네" disabled={(mode !== 'viewer') && true} />
						<FormControlLabel value={2} control={<Radio />} label="아니오" disabled={(mode !== 'viewer') && true} />
					</RadioGroup>
				</li>
				<li>
					4.	최근 2주간 집단 발병 감염지역에 방문한 적이 있습니까?
					<RadioGroup className={classes.row} aria-label="covid19-question" name="a07" value={answer.a07} onChange={handleChange}>
						<FormControlLabel value={1} control={<Radio />} label="네" disabled={(mode !== 'viewer') && true} />
						<FormControlLabel value={2} control={<Radio />} label="아니오" disabled={(mode !== 'viewer') && true} />
					</RadioGroup>
				</li>
				<li>
					5.	가족 및 동거인이 자가격리대상자 및 능동적감시대상자입니까?
					<RadioGroup className={classes.row} aria-label="covid19-question" name="a08" value={answer.a08} onChange={handleChange}>
						<FormControlLabel value={1} control={<Radio />} label="네" disabled={(mode !== 'viewer') && true} />
						<FormControlLabel value={2} control={<Radio />} label="아니오" disabled={(mode !== 'viewer') && true} />
					</RadioGroup>
				</li>
			</ol>
		)
	}

	const switchQNA = () => {
		switch (round) {
			case 6:
				return QNA6th();
			case 7:
				return QNA7th();
			default:
				return QNA6th();
		}
	}

	return(
		<div className={classes.section}>
			<p className={classes.title}>코로나바이러스감염증-19(COVID-19) 관련 사실 확인 여부</p>
			<p>코로나- 19 확산에 따른 정부 및 당사 코로나 대응지침에 따라 pxd에 출입하는 모든 분들에 대해 아래와 같이 확인을 받고 있습니다. 번거로우시겠지만 협조 부탁드립니다.</p>

			{
				switchQNA()
			}

			<p className={`${classes.alignCenter}`}>상기 답변은 본인이 작성하였으며, 사실임을 확인합니다.</p>

			<p className={classes.alignRight}>{_date}</p>
			<p className={classes.alignRight}>작성자 : <span className={classes.bold}>{_userName}</span> (인)</p>
			{ mode === 'view' && signView(signImg) }
			{ mode === 'print' && signView(signImg) }
			{ mode === 'viewer' && setSignPad() }

			<p className={`${classes.bold} ${classes.alignCenter}`}>다시 한 번 협조에 감사드립니다.</p>
			<p className={`${classes.title} ${classes.big}`}>㈜피엑스디 귀중</p>
		</div>
	)
}

export default COVID19QNA;
