import React, { useState, useEffect } from "react";
import UserSurvey01 from "../../../components/templates/UserSurvey/UserSurvey01";
import UserSurvey01En from "../../../components/templates/UserSurvey/UserSurvey01.en";
import UserSurvey02 from "../../../components/templates/UserSurvey/UserSurvey02";
import UserSurvey02En from "../../../components/templates/UserSurvey/UserSurvey02.en";
import UserSurvey03 from "../../../components/templates/UserSurvey/UserSurvey03";
import UserSurvey03En from "../../../components/templates/UserSurvey/UserSurvey03.en";
import UserSurvey04 from "../../../components/templates/UserSurvey/UserSurvey04";
import UserSurvey04En from "../../../components/templates/UserSurvey/UserSurvey04.en";
import moment from "moment";
import "moment/locale/ko";

const UserSurveyViewContainer = ({ detailView, history }) => {
  let section = {
    UserSurvey01: detailView.section_data[0],
    UserSurvey02: detailView.section_data[1],
    UserSurvey03: detailView.section_data[2],
    UserSurvey04: detailView.section_data[3],
  };
  let lang = detailView.results.language;
  let userName = detailView.results.minor_nm;
  let pxdName = detailView.results.officer_nm;
  let email = detailView.results.officer_mail;
  let projectName = detailView.results.project;
  let clientName = detailView.results.major_nm;
  let date = moment(detailView.results.create_date).format("LL");
  let account =
    section.UserSurvey03.section_flag === 1 ? detailView.account[0] : null;

  return (
    <>
      <div>
        <p>사용자조사 개인정보 관련 상세</p>
        {section.UserSurvey01.section_flag === 1 && lang === "en" && (
          <UserSurvey01En
            mode="view"
            idx={section.UserSurvey01.idx}
            userName={userName}
            pxdName={pxdName}
            email={email}
            date={date}
            signImg={section.UserSurvey01.sign_img}
          />
        )}
        {section.UserSurvey01.section_flag === 1 && lang === "ko" && (
          <UserSurvey01
            mode="view"
            idx={section.UserSurvey01.idx}
            userName={userName}
            pxdName={pxdName}
            email={email}
            date={date}
            signImg={section.UserSurvey01.sign_img}
          />
        )}
        {section.UserSurvey02.section_flag === 1 && lang === "en" && (
          <UserSurvey02En
            mode="view"
            idx={section.UserSurvey02.idx}
            projectName={projectName}
            clientName={clientName}
            date={date}
            userName={userName}
            signImg={section.UserSurvey02.sign_img}
          />
        )}
        {section.UserSurvey02.section_flag === 1 && lang === "ko" && (
          <UserSurvey02
            mode="view"
            idx={section.UserSurvey02.idx}
            projectName={projectName}
            clientName={clientName}
            date={date}
            userName={userName}
            signImg={section.UserSurvey02.sign_img}
          />
        )}
        {section.UserSurvey03.section_flag === 1 && lang === "en" && (
          <UserSurvey03En mode="view" account={account} />
        )}
        {section.UserSurvey03.section_flag === 1 && lang === "ko" && (
          <UserSurvey03 mode="view" account={account} />
        )}
        {section.UserSurvey04.section_flag === 1 && lang === "en" && (
          <UserSurvey04En
            mode="view"
            idx={section.UserSurvey04.idx}
            userName={userName}
            projectName={projectName}
            date={date}
            signImg={section.UserSurvey04.sign_img}
          />
        )}
        {section.UserSurvey04.section_flag === 1 && lang === "ko" && (
          <UserSurvey04
            mode="view"
            idx={section.UserSurvey04.idx}
            userName={userName}
            projectName={projectName}
            date={date}
            signImg={section.UserSurvey04.sign_img}
          />
        )}
      </div>
    </>
  );
};

export default UserSurveyViewContainer;
