import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { getDetailView } from "../modules/SignList";
import COVID19ViewContainer from "./containers/COVID19/COVID19ViewContainer";
import UserSurveyViewContainer from "./containers/UserSurvey/UserSurveyViewContainer";
import qs from "qs";
import { isEmpty } from "../lib/utils";
import { Button } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import CreateIcon from "@material-ui/icons/Create";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import * as api from "../apis/SignList";
import Loader from "../components/loader";

const useStyles = makeStyles((theme) => ({
  wrapBtn: {
    textAlign: "center",
  },
}));

const View = ({ location, getDetailView, detailView, history }) => {
  const classes = useStyles();
  useEffect(() => {
    let params = location.search;
    getDetailView(params);
  }, []);

  const switchViewContainer = () => {
    let queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (isEmpty(detailView)) {
      return <Loader />;
    } else {
      return (
        <Fragment>
          {queryParams.type === "sign" && (
            <UserSurveyViewContainer
              mode="view"
              detailView={detailView}
              history={history}
            />
          )}
          {queryParams.type === "survey" && (
            <COVID19ViewContainer
              mode="view"
              detailView={detailView}
              history={history}
            />
          )}
        </Fragment>
      );
    }
  };

  const deleteItem = () => {
    let queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (window.confirm("문서를 삭제하시겠습니까?")) {
      let data = {
        idx: queryParams.idx,
      };
      api.postDeleteSign(data).then((res) => {
        if (res.data.result == true) {
          alert("삭제되었습니다.");
          history.push("/list");
        } else {
          alert("잠시 후 다시 시도해 주세요.");
        }
      });
    }
  };

  return (
    <div>
      {switchViewContainer()}
      <div className={classes.wrapBtn}>
        {detailView != null && detailView.results.sign_status == "false" ? (
          <>
            <Button variant="contained" color="primary" onClick={deleteItem}>
              Delete
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CreateIcon />}
              component={Link}
              to={`/update?type=${detailView.results.sign_type}&idx=${detailView.results.idx}`}
            >
              Update
            </Button>
          </>
        ) : (
          ""
        )}
        <Button
          variant="contained"
          color="primary"
          startIcon={<ListIcon />}
          component={Link}
          to={`/list`}
        >
          LIST
        </Button>
      </div>
    </div>
  );
};

export default connect(
  ({ SignList, loading }) => ({
    detailView: SignList.detailView,
    loading: loading,
  }),
  {
    getDetailView,
  }
)(View);
