import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footer: {
    position: "fixed",
    bottom: 0,
    zIndex: 300,
    padding: "30px 0",
    width: "100%",
    borderTop: "1px solid #ccc",
    backgroundColor: "#fff",
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Typography variant="body2" color="textSecondary" align="center">
        {"© " + new Date().getFullYear() + " "}
        <Link color="inherit" href="http://pxd.co.kr/">
          pxd, inc.
        </Link>
        {" All Rights Reserved."}
      </Typography>
    </footer>
  );
};

export default Footer;
