import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addSaveSignImg } from "../../../modules/SignList";
import { makeStyles } from "@material-ui/core/styles";
import SignaturePad from "signature_pad";
import { isEmpty } from "../../../lib/utils";
import { Button } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";

const useStyles = makeStyles((theme) => ({
  section: {
    marginTop: "30px",
    padding: "20px 0",
    lineHeight: 1.6,
    borderTop: "1px solid #333",
    "& p": {
      marginTop: "10px",
    },
  },
  bold: {
    fontWeight: 700,
  },
  alignRight: {
    textAlign: "right",
  },
  box: {
    margin: "15px 0",
    padding: "15px",
    border: "1px solid #333",
  },
  signPad: {
    marginTop: "20px",
    textAlign: "right",
    "& .signature": {
      display: "inline-block",
      width: "335px",
      height: "200px",
      border: "1px dotted #333",
    },
  },
}));

let canvas;
let signaturePad;

const UserSurvey01 = ({
  mode,
  idx,
  userName,
  pxdName,
  email,
  date,
  addSaveSignImg,
  signImg,
  signImgList,
}) => {
  const classes = useStyles();
  // const [signFlag, setSignFlag] = useState(false);

  useEffect(() => {
    if (mode === "viewer") {
      canvas = document.querySelector(`#signature${idx}`);
      signaturePad = new SignaturePad(canvas);
    }
  }, []);

  const saveSign = (e) => {
    let data = signaturePad.toDataURL("image/png");

    if (signaturePad.isEmpty()) {
      alert("서명 후 저장해주세요.");
      return false;
    }

    // setSignFlag(true);
    signImgList(0, data);
    alert("저장되었습니다.");

    let thisEl = e.target.closest(".section");
    let nextEl = thisEl.nextElementSibling;
    let nextElFlag =
      nextEl && thisEl.nextElementSibling.classList.contains("section");

    if (nextElFlag) {
      // const y = nextEl.getBoundingClientRect().top + window.scrollY;

      let ua = window.navigator.userAgent;
      let msie = ua.indexOf("MSIE ");

      if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        // If Internet Explorer
        window.scrollTo(0, nextEl.offsetTop);
      } else {
        // If another browser
        // not support ios safari -_-;;
        window.scroll({
          top: nextEl.offsetTop,
          behavior: "smooth",
        });
      }
    }
  };

  const clearSign = () => {
    signaturePad.clear();
  };

  const setSignPad = () => {
    return (
      <div className={classes.signPad}>
        <canvas
          id={`signature${idx}`}
          className="signature"
          width="335px"
          height="200px"
        ></canvas>
        <div className={classes.wrapBtn}>
          <Button
            className="clear"
            variant="contained"
            // disabled={signFlag}
            startIcon={<ClearIcon />}
            onClick={clearSign}
          >
            Clear
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            className="save"
            variant="contained"
            color="primary"
            // disabled={signFlag}
            startIcon={<CreateIcon />}
            onClick={(e) => saveSign(e)}
          >
            Save
          </Button>
        </div>
      </div>
    );
  };

  const signView = (imgSrc) => {
    if (!isEmpty(imgSrc)) {
      return (
        <div className={classes.signPad}>
          <img src={imgSrc} className="signature" alt="사인 이미지" />
        </div>
      );
    }
  };

  return (
    <div className={`${classes.section} section`}>
      <p>
        Dear Mr/Ms <span className={classes.bold}>{userName}</span>
      </p>
      <p>First of all, thank you very much for participating in this survey.</p>
      <p>
        PXD Co., Ltd., which conducts this survey, is a UX Consulting firm that
        has improved the user experience through user surveys and usability
        tests since its establishment in 2002.
      </p>
      <p>
        This study is conducted for research by PXD Co., Ltd. and a third party
        receiving information about the results of the investigation. The
        contents observed and created through this survey will be used by
        researchers to analyze the data. In addition, the information obtained
        through the investigation is not used for any purpose other than for
        research purposes and is never leaked to the outside. Please read the
        following sentence and sign it.
      </p>
      <div className={classes.box}>
        <p>
          I understand that in this survey, I understand the process of use,
          usage patterns, and my thoughts, feelings, and opinions about a
          specific service or product.
        </p>
        <p>
          I am aware that this survey may involve actual product use,
          photography, and voice recording.
        </p>
        <p>
          I understand that during the course of the investigation, I may not
          answer questions/tasks that are offensive or undesired to be answered,
          and that there is no penalty for this.
        </p>
        <p>
          I understand that I have the right to quit at any time if I believe
          that the investigation does not meet my standards ethically or
          legally, and I understand that there will be no penalty for this.
        </p>
        <p>
          I am aware that during the investigation period, information may be
          obtained on matters not yet disclosed to the public. In that case, I
          will not pass on what I learned during the investigation to third
          parties who did not participate in this investigation.
        </p>
        <p>The person responsible for this investigation is as follows. </p>
        <p>
          Director: <span className={classes.bold}>{pxdName}</span>
        </p>
        <p>
          Email: <span className={classes.bold}>{email}</span>
        </p>
      </div>
      <p className={classes.alignRight}>{date}</p>
      <p className={classes.alignRight}>
        Full Name : <span className={classes.bold}>{userName}</span>(Handwriting
        required) Signature
      </p>

      {mode === "view" && signView(signImg)}
      {mode === "print" && signView(signImg)}
      {mode === "viewer" && setSignPad()}
    </div>
  );
};

export default connect(({ SignList }) => ({}), {
  addSaveSignImg,
})(UserSurvey01);
