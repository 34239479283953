import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Button } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import UserSurveyWriteContainer from "./containers/UserSurvey/UserSurveyWriteContainer";
import COVID19WriteContainer from "./containers/COVID19/COVID19WriteContainer";
import * as api from "../apis/SignList";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  alignCenter: {
    "& > *": {
      margin: theme.spacing(1),
    },
    textAlign: "center",
  },
}));

const Write = ({ history }) => {
  const classes = useStyles();
  const [documentType, setDocumentType] = useState("");
  const [language, setLanguage] = useState("ko");
  const [data, setData] = useState(null);
  const [applyChecker, setApplyChecker] = useState(false);

  const handleChange = (event) => {
    setDocumentType(event.target.value);
  };

  const handleChangeLang = (event) => {
    setLanguage(event.target.value);
  };

  const onApplyChecker = (flag) => {
    setApplyChecker(flag);
  };

  const onCreate = () => {
    api.postAddSign(data).then((res) => {
      if (res.data.result) {
        history.push("/list");
      }
    });
  };

  const setTemplateDocument = () => {
    switch (documentType) {
      case "userSurvey":
        // 사용자조사 개인정보 관련
        return (
          <UserSurveyWriteContainer
            data={(value) => setData(value)}
            applyChecker={onApplyChecker}
            lang={language}
          />
        );
      case "covid19":
        // 코로나바이러스감염증-19(COVID-19) 관련 사실 확인 여부
        return (
          <COVID19WriteContainer
            data={(value) => setData(value)}
            applyChecker={onApplyChecker}
          />
        );
      default:
        return (
          <UserSurveyWriteContainer
            data={(value) => setData(value)}
            applyChecker={onApplyChecker}
            lang={language}
          />
        );
      // return <COVID19WriteContainer data={(value) => setData(value)} applyChecker={onApplyChecker}/>
    }
  };

  return (
    <div>
      <div>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="documentType">Type</InputLabel>
          <Select
            labelId="documentType"
            id="documentType"
            value={documentType}
            onChange={handleChange}
            label="Type"
          >
            <MenuItem value={"userSurvey"}>사용자조사 개인정보 관련</MenuItem>
            <MenuItem value={"covid19"}>
              코로나바이러스 감염증 관련 사실확인 여부
            </MenuItem>
            {/*<MenuItem value={2}>보안 서약서</MenuItem>*/}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="language">언어선택</InputLabel>
          <Select
            labelId="language"
            id="language"
            value={language}
            onChange={handleChangeLang}
            label="language"
          >
            <MenuItem value={"ko"} selected>
              국문
            </MenuItem>
            <MenuItem value={"en"}>영문</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div>{setTemplateDocument()}</div>
      <div className={classes.alignCenter}>
        {/*<Button*/}
        {/*	variant="contained"*/}
        {/*	startIcon={<VisibilityIcon/>}*/}
        {/*	onClick={onPreview}*/}
        {/*>*/}
        {/*	Preview*/}
        {/*</Button>*/}
        <Button
          variant="contained"
          color="primary"
          disabled={!applyChecker}
          startIcon={<CreateIcon />}
          onClick={onCreate}
        >
          Create
        </Button>
      </div>
    </div>
  );
};

export default Write;
