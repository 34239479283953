import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { headerVisibility, footerVisibility } from "../modules/ui";
import { getDetailViewer } from "../modules/SignList";
import * as api from "../apis/SignList";
import COVID19ViewerContainer from "./containers/COVID19/COVID19ViewerContainer";
import UserSurveyViewerContainer from "./containers/UserSurvey/UserSurveyViewerContainer";
import qs from "qs";
import { isEmpty } from "../lib/utils";
import CreateIcon from "@material-ui/icons/Create";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../components/loader";

const useStyles = makeStyles((theme) => ({
  alignCenter: {
    textAlign: "center",
  },
}));

const Viewer = ({
  location,
  history,
  match,
  getDetailViewer,
  detailViewer,
  headerVisibility,
  footerVisibility,
}) => {
  const classes = useStyles();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }).mode;
  const mode = isEmpty(queryParams) ? "viewer" : queryParams;
  const [data, setData] = useState(null);
  const [signImg, setSignImg] = useState(null);
  const [checker, setChecker] = useState(false);
  let checkerArr = [false, false, false, false];

  useEffect(() => {
    headerVisibility(false);
    footerVisibility(false);
    try {
      getDetailViewer(match.params.key);
    } catch (e) {
      history.push("/error");
    }
  }, []);

  useEffect(() => {
    if (isEmpty(detailViewer)) {
    } else {
      if (mode === "print") {
        // Print Mode
        setTimeout(onPrint, 1000);
        // onPrint();
      } else {
        let status = detailViewer.results.sign_status;
        if (status === "complete") {
          alert("이미 완료되었습니다.");
          history.push("/complete");
        }
      }
    }
  }, [detailViewer]);

  const onPrint = () => {
    // window.print();
    const printableElements = document.querySelector(".print-area").innerHTML;
    const orderHTML =
      "<html><head><title></title></head><body>" +
      printableElements +
      "</body></html>";
    const oldPage = document.body.innerHTML;
    document.body.innerHTML = orderHTML;
    window.print();
    document.body.innerHTML = oldPage;
  };

  const signImgData = (img) => {
    setSignImg(img);
  };

  const onSubmit = () => {
    let sendData;
    if (detailViewer.type === "sign") {
      sendData = {
        idx: detailViewer.results.idx, // 문서 인덱스 번호
        type: detailViewer.type,
        account: data,
        sectionList: [
          {
            idx: detailViewer.section[0].idx,
            sign_img: signImg[0],
          },
          {
            idx: detailViewer.section[1].idx,
            sign_img: signImg[1],
          },
          {
            idx: detailViewer.section[2].idx,
            sign_img: null,
          },
          {
            idx: detailViewer.section[3].idx,
            sign_img: signImg[3],
          },
        ],
      };

      detailViewer.section.forEach((v, i) => {
        if (i === 2) {
          // 계좌정보 입력이 없을때 체크
          if (v.section_flag === 1 && isEmpty(data)) {
            checkerArr[i] = true;
          }
        } else {
          // 싸인 이미지가 없을때 체크
          if (v.section_flag === 1 && isEmpty(signImg[i])) {
            checkerArr[i] = true;
          }
        }
      });

      if (checkerArr[0]) {
        alert("1서명 후 저장이 필요합니다.");
        return false;
      }
      if (checkerArr[1]) {
        alert("2서명 후 저장이 필요합니다.");
        return false;
      }
      if (checkerArr[2]) {
        alert("3입력 후 저장이 필요합니다.");
        return false;
      }
      if (checkerArr[3]) {
        alert("4서명 후 저장이 필요합니다.");
        return false;
      }
    } else if (detailViewer.type === "survey") {
      sendData = {
        idx: detailViewer.results.idx,
        type: detailViewer.type,
        sign_img: signImg,
        section_idx: detailViewer.results.section_idx,
        question: data,
      };

      if (isEmpty(signImg) || isEmpty(data)) {
        alert("미입력된 항목이 있습니다.");
        return false;
      }
    }

    api.postUpdateUserSign(sendData).then((res) => {
      setChecker(true);

      history.push("/complete");
    });
  };

  const switchViewContainer = () => {
    if (isEmpty(detailViewer)) {
      return <Loader />;
    } else {
      return (
        <Fragment>
          {detailViewer.type === "sign" && (
            <UserSurveyViewerContainer
              mode={mode}
              detailView={detailViewer}
              data={(value) => setData(value)}
              signImg={signImgData}
            />
          )}
          {detailViewer.type === "survey" && (
            <COVID19ViewerContainer
              mode={mode}
              detailView={detailViewer}
              data={(value) => setData(value)}
              signImg={signImgData}
            />
          )}
        </Fragment>
      );
    }
  };

  const submitBtn = () => {
    return (
      <div className={classes.alignCenter}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<CreateIcon />}
          onClick={onSubmit}
          disabled={checker}
        >
          Submit
        </Button>
      </div>
    );
  };

  return (
    <div className="print-area">
      {switchViewContainer()}
      {mode !== "print" && submitBtn()}
    </div>
  );
};

export default connect(
  ({ SignList, loading }) => ({
    detailViewer: SignList.detailViewer,
    loading: loading,
  }),
  {
    headerVisibility,
    footerVisibility,
    getDetailViewer,
  }
)(Viewer);
