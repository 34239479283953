import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import {isEmpty} from "../../../lib/utils";
import {Button} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import InputMask from "react-input-mask";

const useStyles = makeStyles((theme) => ({
	section: {
		marginTop: "30px",
		padding: "20px 0",
		lineHeight: 1.6,
		borderTop: "1px solid #333",
		"& p": {
			marginTop: "10px"
		},
	},
	title: {
		padding: "15px 0",
		fontSize: "20px",
		fontWeight: 700,
		textAlign: "center"
	},
	wrapBtn: {
		padding: "10px",
		textAlign: "right"
	},
	onlyNum: {
		display: "inline-block",
		lineHeight: "48px",
	},
	mr: {
		marginRight: "20px"
	}
}));

const { useState } = React;

const TextMaskContact = (props) => {
	const { inputRef, ...other } = props;

	return(
		<InputMask
			{...other}
			ref={inputRef}
			mask="999-9999-9999"
			maskchar={null}
		/>
	)
}

const TextMaskUniqueNum = (props) => {
	const { inputRef, ...other } = props;

	return(
		<InputMask
			{...other}
			ref={inputRef}
			mask="999999-9******"
			maskchar={null}
		/>
	)
}

const UserSurvey03 = ({mode, userData, account}) => {
	const classes = useStyles();
	const [bankName, setBankName] = useState((isEmpty(account)) ? '' : account.bank_nm);
	const [bankNum, setBankNum] = useState((isEmpty(account)) ? '' : account.bank_num);
	const [name, setName] = useState((isEmpty(account)) ? '' : account.bank_user);
	const [uniqueNum, setUniqueNum] = useState((isEmpty(account)) ? '' : account.bank_user_num);
	const [contact, setContact] = useState((isEmpty(account)) ? '' : account.bank_user_phone);

	const onSave = (e) => {
		if (isEmpty(bankName)) {
			alert('은행명을 입력해주세요.');
			document.querySelector('[name=bankName]').focus();
			return false;
		}
		if (isEmpty(bankNum)) {
			alert('계좌번호를 입력해주세요.');
			document.querySelector('[name=bankNum]').focus();
			return false;
		}
		if (isEmpty(name)) {
			alert('이름을 입력해주세요.');
			document.querySelector('[name=name]').focus();
			return false;
		}
		if (isEmpty(uniqueNum) || uniqueNum.includes('_')) {
			alert('주민등록번호를 입력해주세요.');
			document.querySelector('[name=uniqueNum]').focus();
			return false;
		}
		if (isEmpty(contact) || contact.includes('_')) {
			alert('연락처를 입력해주세요.');
			document.querySelector('[name=contact]').focus();
			return false;
		}

		let data = {
			bank_nm: bankName,
			bank_num: bankNum,
			bank_user: name,
			bank_user_num: uniqueNum,
			bank_user_phone: contact
		}

		userData(data);
		alert('저장되었습니다.');

		let thisEl = e.target.closest('.section');
		let nextEl = thisEl.nextElementSibling;
		let nextElFlag = nextEl && thisEl.nextElementSibling.classList.contains('section');

		if (nextElFlag) {
			// const y = nextEl.getBoundingClientRect().top + window.scrollY;

			let ua = window.navigator.userAgent;
			let msie = ua.indexOf("MSIE ");

			if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
				// If Internet Explorer
				window.scrollTo(0, nextEl.offsetTop)
			} else  {
				// If another browser
				// not support ios safari -_-;;
				window.scroll({
					top: nextEl.offsetTop,
					behavior: 'smooth'
				});
			}
		}
	}

	const setBankNumInput = (target, value) => {
		let regNumber = /^[0-9]*$/;
		let temp = value;
		if(!regNumber.test(temp)) {
			target.value = temp.replace(/[^0-9]/g,"");
		}
		setBankNum(target.value);
	}

	return(
		<div className={`${classes.section} section`}>
			<p className={classes.title}>
				사례비 지급 및 지급 후 세무/회계 처리를 위해<br/>
				아래 정보를 기입해 주십시오.
			</p>

			<Table aria-label="table">
				<TableBody>
					<TableRow>
						<TableCell component="th" style={{ width: 160 }}>지급은행</TableCell>
						<TableCell>
							<TextField label="은행명" name="bankName" value={bankName} disabled={mode !== 'viewer'} onChange={(e) => setBankName(e.target.value)}/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>계좌번호</TableCell>
						<TableCell>
							<TextField className={classes.mr} label="계좌번호" name="bankNum" value={bankNum} disabled={mode !== 'viewer'} onChange={(e) => setBankNumInput(e.target, e.target.value)}/>
							<span className={classes.onlyNum}>* 숫자만 입력해주세요.</span>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>예금주</TableCell>
						<TableCell>
							<TextField label="이름" name="name" value={name} disabled={mode !== 'viewer'} onChange={(e) => setName(e.target.value)}/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>예금주 주민등록번호</TableCell>
						<TableCell>
							<TextField
								label="주민등록번호"
								name="uniqueNum"
								value={uniqueNum}
								disabled={mode !== 'viewer'}
								InputProps={{
									inputComponent: TextMaskUniqueNum,
									value: uniqueNum,
									onChange: (e) => setUniqueNum(e.target.value)
								}}
							/>
							<span className={classes.onlyNum}>* 숫자만 입력해주세요.</span>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>예금주 연락처</TableCell>
						<TableCell>
							<TextField
								label="연락처"
								name="contact"
								value={contact}
								disabled={mode !== 'viewer'}
								InputProps={{
									inputComponent: TextMaskContact,
									value: contact,
									onChange: (e) => setContact(e.target.value)
								}}
							/>
							<span className={classes.onlyNum}>* 숫자만 입력해주세요.</span>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>

			{
				mode === 'viewer' &&
				<div className={classes.wrapBtn}>
					<Button
						variant="contained"
						color="primary"
						// disabled={apply}
						startIcon={<CreateIcon/>}
						onClick={(e) => onSave(e)}
					>
						Save
					</Button>
				</div>
			}
		</div>
	)
};

export default UserSurvey03;
