import request from "./request";

export const getList = (queryParams = '') => {
	return request({
		url: `/admin/signatureList${queryParams}`
	});
};

export const getDetailView = (params) => {
	return request({
		url: `/admin/getDetail${params}`
	});
};

export const getDetailViewer = (params) => {
	return request({
		url: `/users/getDetail/${params}`
	});
};

export const getCovidDetail = (idx) => {
	return request({
		url: `/admin/signSurveryDetail?idx=${idx}`
	});
}

export const getUserSurveyDetail = (idx) => {
	return request({
		url: `/admin/signPrivacyDetail?idx=${idx}`
	});
}

export const postAddSign = (data) => {
	return request({
		url: `/admin/addSign`,
		method: 'post',
		data: data
	});
};

export const postAddPrivacyAdd = (data) => {
	return request({
		url: `/admin/signPrivacyAdd`,
		method: 'post',
		data: data
	});
};

export const postUpdateSign = (data) => {
	return request({
		url: `/admin/updateSign`,
		method: 'post',
		data: data
	})
};

export const postUpdateUserSign = (data) => {
	return request({
		url: `/users/updateSign`,
		method: 'post',
		data: data
	});
}

export const postDeleteSign = (data) => {
	return request({
		url: `/admin/deleteSign`,
		method: 'post',
		data: data
	});

}
