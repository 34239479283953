import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {getUserList, applyUser} from "../modules/User";
import { isEmpty } from '../lib/utils';
import * as utils from '../lib/utils';
import session from '../lib/sessionStorage';
import * as api from '../apis/User'

import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PrintIcon from '@material-ui/icons/Print';
import LoopIcon from '@material-ui/icons/Loop';
// import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import SendIcon from '@material-ui/icons/Send';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LinkIcon from '@material-ui/icons/Link';
import {Button} from "@material-ui/core";
import ListIcon from '@material-ui/icons/List';

const useStyles1 = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
}));

function UserPaginationActions(props) {
	const classes = useStyles1();
	const theme = useTheme();
	const { count, page, rowsPerPage, onChangePage } = props;
	const handleFirstPageButtonClick = (event) => {
		onChangePage(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onChangePage(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onChangePage(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<div className={classes.root}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</div>
	);
}

UserPaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onChangePage: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

function createData(idx, userName, isApply) {
	return { idx, userName, isApply };
}

let rows = [];

const useStyles2 = makeStyles((theme) => ({
	wrapTable: {
		margin: "20px auto 0",
		maxWidth: "1400px"
	},
	table: {
		minWidth: 500,
	},
	tableHead: {
		backgroundColor: "#333",
		"& th": {
			color: "#fff"
		}
	},
	tr: {
		"&:hover": {
			backgroundColor: "#efefef"
		}
	},
	cursor: {
		cursor: "pointer",
	},
	link: {
		color: "#000",
		textDecoration: "none"
	},
	wrapBtn: {
		marginTop: "20px",
		textAlign: "right"
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	wrapSearch: {
		textAlign: "right"
	},
	wrapSearchType: {
		display: "inline-block",
		marginRight: "15px",
		lineHeight: "82px"
	},
	wrapSearchText: {
		display: "inline-block",
		marginRight: "15px",
		lineHeight: "64px"
	},
	wrapSearchBtn: {
		display: "inline-block",
		lineHeight: "64px"
	}
}));

const onChangeState = () => {
	
}
onChangeState();

const UserManagement = ({data, getUserList}) => {
	const classes = useStyles2();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	useEffect( () => {
		getData();
	}, []);

	useEffect( () => {
        if (!isEmpty(data)) {
			let listData = data.result;
			rows = [];
			listData.forEach( (v, i) => {
				rows.push(createData(v.idx, v.user_name, v.is_apply))
			})
		}
	}, [data])

	const getData = () => {
		
		let getUserLists = async () => {
            await getUserList('');
		};
		getUserLists();
	}

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const onChangeStatus = (key) => {
		if (window.confirm("사용자 권한을 변경하시겠습니까?")) {
			let params = {"user_idx":key};
			api.applyUser(params).then((res) => {
				if (res.data.result == "success") {
					getData();
				}
			});
		}

	}


	return (
		<div className={classes.wrapTable}>
			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="custom pagination table">
					<TableHead>
						<TableRow className={classes.tableHead}>
							<TableCell align="center">No</TableCell>
							<TableCell align="center">이름</TableCell>
							<TableCell align="center">상태</TableCell>
							<TableCell align="center">변경</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0
								? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								: rows
						).map((row, index) => (
							<TableRow className={classes.tr} key={index}>
								<TableCell component="th" scope="row" align="center" style={{ width: 100 }}>
									{/*{row.type}*/}
									{row.idx}
								</TableCell>
								<TableCell align="center">
                                    {row.userName}
								</TableCell>
								<TableCell style={{ width: 100, wordBreak: "keep-all" }} align="center">
									{row.isApply==1 ? '승인' : '승인대기'}
								</TableCell>
								<TableCell style={{ width: 100, wordBreak: "keep-all" }} align="center">
									{row.isApply==0?
										<IconButton onClick={() => onChangeStatus(`${row.idx}`)}>
											<LoopIcon />
										</IconButton> : ''
									}
								</TableCell>
							</TableRow>
						))}

						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell align="center" colSpan={3}></TableCell>
							</TableRow>
						)}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
								colSpan={6}
								count={rows.length}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: { 'aria-label': 'rows per page' },
									native: true,
								}}
								onChangePage={handleChangePage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
								ActionsComponent={UserPaginationActions}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</div>
	);
}

export default connect(
	({User, loading}) => ({
		data: User.data,
		loading: loading
	}),
	{
		getUserList
	}
)(UserManagement);
