import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import moment from "moment";
import {Button} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import {isEmpty} from "../../../lib/utils";
import COVID19QNA from "../../../components/templates/COVID19/COVID19QNA";

import session from '../../../lib/sessionStorage';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		padding: "10px"
	},
	section: {
		marginTop: "30px",
		padding: "20px 0",
		lineHeight: 1.6,
		borderTop: "1px solid #333",
		"& p": {
			marginTop: "10px"
		},
		"& > ol > li": {
			marginTop: "20px",
			padding: "0 20px"
		},
	},
	title: {
		padding: "15px 0",
		fontSize: "20px",
		fontWeight: 700,
		textAlign: "center"
	},
	alignCenter: {
		textAlign: "center"
	},
	alignRight: {
		textAlign: "right"
	},
	big: {
		fontSize: "30px"
	},
	bold: {
		fontWeight: 700
	},
	row: {
		display: "flex",
		flexWrap: "wrap",
		flexDirection: "row",
		justifyContent: "space-around",
		maxWidth: "300px"
	},
	wrapBtn: {
		padding: "10px",
		textAlign: "right"
	},
	download: {
		display: "block",
		color: "#333",
		textAlign: "right"
	}
}));

const COVID19WriteContainer = ({data, applyChecker}) => {
	const classes = useStyles();
	const [userName, setUserName] = useState('홍길동');
	const [date, setDate] = useState(moment().format('LL'));
	const [apply, setApply] = useState(false);

	useEffect( () => {
		setApply(false);
		applyChecker(false);
	}, [userName, date]);

	const onApply = () => {
		let el = document.querySelectorAll('[name=text-field]');
		let el_arr = [...el];
		let checker = false;

		el_arr.some((v, i) => {
			if (isEmpty(v.value)) {
				checker = false;
				v.focus();
				return true;
			} else {
				checker = true;
			}
		})

		if (checker) {
			let _6th = [
				{
					question: '현재 37.5℃ 이상의 발열, 기침, 목아픔 등의 증상이 있습니까?',
					answer: 0
				},
				{
					question: '현재 질병관리본부, 코로나 중앙관리대책본부 등에서 연락 받은 관리대상자입니까?',
					answer: 0
				},
				{
					question: '현재 코로나-19에 대한 능동적 감시대상자입니까?',
					answer: 0
				},
				{
					question: '지난 4월 24일 ~ 5월 10일 중 서울 이태원 지역의 클럽, 식당 등의 사업장 방문자이거나, 방문자의 가족 혹은 밀접접촉자 입니까?',
					answer: 0
				},
				{
					question: '지난 4월 24일 ~ 5월 10일 중 서울 홍대/신촌 지역의 클럽, 식당 등의 사업장 방문자이거나, 방문자의 가족 혹은 밀접접촉자 입니까?',
					answer: 0
				},
				{
					question: '현재까지 발표된 확진자와 동선이 겹치는 경우가 있습니까?',
					answer: 0
				}
			];

			let _7th = [
				{
					question: '현재 37.5℃ 이상의 발열, 기침, 목아픔 등의 증상이 있습니까?',
					answer: 0
				},
				{
					question: '현재 질병관리본부, 코로나 중앙관리대책본부 등에서 연락 받은 관리대상자입니까?',
					answer: 0
				},
				{
					question: '현재 코로나-19에 대한 능동적 감시대상자입니까?',
					answer: 0
				},
				{
					question: '최근 2주간 집단 발병 감염지역에 방문한 적이 있습니까?',
					answer: 0
				},
				{
					question: '가족 및 동거인이 자가격리대상자 및 능동적감시대상자입니까?',
					answer: 0
				},
			]

			let _docData = {
				sign_type: 'survey',
				project: '코로나바이러스감염증-19(COVID-19) 관련 사실 확인 여부',
				minor_nm: userName,
				round: 7,
				question: _7th,
				user_idx:JSON.parse(session.get('userIdx')),
			};

			applyChecker(checker);
			setApply(checker);
			data(_docData)
		}
	}

	return(
		<div className={classes.wrapper}>
			<div>
				<a href="/files/코로나사실_확인설문지_5차.docx" className={classes.download} download>Word 문서 다운로드</a>
				<Table aria-label="table">
					<TableBody>
						<TableRow>
							<TableCell>작성자</TableCell>
							<TableCell>
								<TextField label="이름" name="text-field" onChange={(e) => setUserName(e.target.value)}/>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<div className={classes.wrapBtn}>
					<Button
						variant="contained"
						color="primary"
						disabled={apply}
						startIcon={<CreateIcon/>}
						onClick={onApply}
					>
						Apply
					</Button>
				</div>
			</div>
			{
				<COVID19QNA mode="write" userName={userName} date={date}/>
			}
		</div>
	)
};

export default COVID19WriteContainer;
