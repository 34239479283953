import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import * as api from '../apis/User';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

const Join = ({history}) => {
	const classes = useStyles();
	const [id, setId] = useState(null);
	const [name, setName] = useState(null);
	const [pw, setPw] = useState(null);
	const [repw, setRePw] = useState(null);

	useEffect( () => {
		// if (isLogin) {
		// 	history.push('/list');
		// }
	}, []);

	useEffect(() => {
		// Submit Login Result
		// if (!utils.isEmpty(loginStatus)) {
		// 	let status = loginStatus.result.status;

		// 	if (status === 'success') {
		// 		if(loginStatus.result.is_apply == 0){
		// 			alert('관리자 승인 대기중 입니다.')
		// 		}else{
		// 			let isLogin = true;
		// 			let token = loginStatus.result.jsontoken;
		// 			session.set('isLogin', isLogin);
		// 			session.set('token', token);
		// 			session.set('isAdmin', loginStatus.result.is_admin);
		// 			session.set('userIdx', loginStatus.result.user_idx);
	
		// 			// history.push('/list');
		// 			history.push('/userList');
		// 		}
		// 	} else {
		// 		// Login Failed
		// 		alert('ID 또는 Password를 다시 확인해주세요.');

		// 		let inputId = document.querySelector('[name=userid]');
		// 		let inputPw = document.querySelector('[name=password]')

		// 		inputPw.value = '';
		// 		inputId.value = '';
		// 		inputId.focus();

		// 		setId(null);
		// 		setPw(null);
		// 	}
		// }
	}, [])

	const submitJoin = async (e) => {
		e.preventDefault();
        if(!pw){
            alert('비밀번호를 입력해 주세요')
        }else if(!id){
            alert('아이디를 입력해 주세요')
        }else if(!name){
            alert('이름을 입력해 주세요')
        }else{
            if(pw === repw){
                let data = {
                    user_id: id,
                    user_name:name,
                    user_passwd: pw
                };
                api.addUser(data).then((res) => {
                    if (res.data.result == 'duplicate') {
                        alert('중복된 아이디 입니다.')
                    }else if(res.data.result == 'success') {
                        alert('계정을 생성하였습니다.');
                        history.push('/login');
                    }else{
                        alert('잠시 후 다시 시도해 주세요.');
                    }
                });
            }else{
                alert('비밀번호를 확인해 주세요');
            }
        }
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Sign up
				</Typography>
				<form className={classes.form} noValidate onSubmit={submitJoin} >
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="user_id"
						label="User ID"
						name="userid"
						autoComplete="id"
						autoFocus
						onChange={(e) => setId(e.target.value)}
					/>
                    <TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="user_name"
						label="User Name"
						name="userName"
						autoComplete="name"
						autoFocus
						onChange={(e) => setName(e.target.value)}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						autoComplete="current-password"
						onChange={(e) => setPw(e.target.value)}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password 확인"
						type="password"
						id="passwordCheck"
						autoComplete="current-password"
						onChange={(e) => setRePw(e.target.value)}
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
					>
						가입하기
					</Button>
				</form>
			</div>
		</Container>
	);
};

export default Join;
