import { createAction, handleActions } from 'redux-actions';

const SET_HEADER_VISIBILITY = 'ui/SET_HEADER_VISIBILITY';
const SET_FOOTER_VISIBILITY = 'ui/SET_FOOTER_VISIBILITY';

export const setHeaderVisibility = createAction(SET_HEADER_VISIBILITY);
export const setFooterVisibility = createAction(SET_FOOTER_VISIBILITY);

export const headerVisibility = (flag) => dispatch => {
	dispatch(setHeaderVisibility(flag));
}

export const footerVisibility = (flag) => dispatch => {
	dispatch(setFooterVisibility(flag));
}

const initialState = {
	header: true,
	footer: true
}

const ui = handleActions(
	{
		[SET_HEADER_VISIBILITY]: (state, action) => ({
			...state,
			header: action.payload
		}),
		[SET_FOOTER_VISIBILITY]: (state, action) => ({
			...state,
			footer: action.payload
		}),
	},
	initialState
);

export default ui;
